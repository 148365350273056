import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import { fDateTo } from '../../utils/formatTime';
import CarsTypeDialog from './CarsTypeDialog';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import axios from 'axios';

export default function DialogTrip({ onHandleSubmit, booking_id, pickup_date, dropoff_date, userId }) {
    console.log('pickup_date', pickup_date)
    const [initialValues, setinitialValues] = useState({
        "trip_type": "",
        "trip_start_datetime": pickup_date,
        "trip_end_datetime": dropoff_date,
    })

    useEffect(() => {
        setinitialValues({
            "trip_type": "",
            "trip_start_datetime": pickup_date,
            "trip_end_datetime": dropoff_date,
        })
    }, [booking_id, pickup_date, dropoff_date])

    const FormSchema = Yup.object().shape({
        trip_type : Yup.string().required("กรุณาใส่กรุ๊ปโค้ด")
    });
    const formik = useFormik({
        initialValues: {
            ...initialValues
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setValues }) => {
            axios.post(`https://booking.sitgps.com/booking/create_trip`, {
                "trip_type": values.trip_type,
                trip_start_datetime: `${values.trip_start_datetime?.replaceAll("T", " ")?.replaceAll(".000Z", "")}`,
                trip_end_datetime: `${values.trip_end_datetime?.replaceAll("T", " ")?.replaceAll(".000Z", "")}`,
                booking_id: booking_id,
                "booking_userid": userId
            })
                .then(res => {
                    setinitialValues({
                        "trip_type": "",
                        "trip_start_datetime": pickup_date,
                        "trip_end_datetime": dropoff_date,
                    })
                    onHandleSubmit(booking_id)
                    setOpen(false)
                })
                .catch(err => {
                    console.log('err', err)
                })
        }
    });
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        formik.resetForm()
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const onChangeBookingDate = (name, value) => {
        formik.setFieldValue(name, value)
    }

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between">
                <Box>
                    <Typography>รายละเอียดการเดินทาง</Typography>
                </Box>
                <Box>
                    <Button variant="outlined" size="small" color="error" onClick={handleOpen} disabled={!booking_id}>
                        {/* <AddOutlinedIcon fontSize="small" /> */}
                        สร้างทริป
                    </Button>
                </Box>
            </Stack >
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>รายละเอียดการเดินทาง</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack >
                                        <Grid container spacing={1} >
                                            {/* <Grid item xs={12} md={4}>
                                                <TextField size="small" label='ประเภท' placeholder='ประเภท' value={formik.values.trip_type} name='trip_type' fullWidth
                                                    {...formik.getFieldProps('trip_type')}
                                                    error={Boolean(formik.touched.trip_type && formik.errors.trip_type)}
                                                    helperText={formik.touched.trip_type && formik.errors.trip_type}
                                                />
                                            </Grid> */}
                                            <Grid item xs={12} md={4}>
                                                <TextField size="small" label='กรุ๊ปโค้ด' placeholder='ประเภท' value={formik.values.trip_type} name='trip_type' fullWidth
                                                    {...formik.getFieldProps('trip_type')}
                                                    error={Boolean(formik.touched.trip_type && formik.errors.trip_type)}
                                                    helperText={formik.touched.trip_type && formik.errors.trip_type}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} sx={{ marginTop: "15px", marginBottom: "15px" }}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField fullWidth size="small"
                                                    label="รับเวลา"
                                                    type="datetime-local" name='trip_start_datetime'
                                                    {...formik.getFieldProps('trip_start_datetime')}
                                                    value={(formik.values?.trip_start_datetime)?.replaceAll("T", " ").replaceAll(".000Z", "")}
                                                    InputProps={{ inputProps: { min: pickup_date, max: dropoff_date } }}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={6} md={6}>
                                                <TimePicker
                                                    ampm={false}
                                                    label="รับเวลา"
                                                    value={formik.values.trip_start_time}
                                                    name='trip_start_time'
                                                    onChange={(value) => { onChangeBookingDate("trip_start_time", value) }}
                                                    renderInput={(params) => <TextField size="small" label='รับเวลา' name='trip_start_time' fullWidth {...params} />}
                                                />
                                            </Grid> */}
                                            {/* <Grid item xs={6} md={6}>
                                                <DesktopDatePicker
                                                    label="วันที่"
                                                    inputFormat="dd/MM/yyyy"
                                                    value={formik.values.trip_end_datetime}
                                                    name='trip_end_datetime'
                                                    onChange={(value) => { onChangeBookingDate("trip_end_datetime", value) }}
                                                    renderInput={(params) => <TextField size="small" label='วันที่' name='trip_end_datetime' fullWidth {...params} />}
                                                    minDate={pickup_date}
                                                    maxDate={dropoff_date}
                                                />
                                            </Grid> */}
                                            <Grid item xs={6} md={6}>
                                                <TextField fullWidth size="small"
                                                    label="ส่งเวลา"
                                                    type="datetime-local" name='trip_end_datetime'
                                                    {...formik.getFieldProps('trip_end_datetime')}
                                                    value={(formik.values?.trip_end_datetime)?.replaceAll("T", " ").replaceAll(".000Z", "")}
                                                    InputProps={{ inputProps: { min: pickup_date, max: dropoff_date } }}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={6} md={6}>
                                                <TimePicker
                                                    ampm={false}
                                                    label="ส่งเวลา"
                                                    value={formik.values.trip_end_datetime}
                                                    name='trip_end_datetime'
                                                    onChange={(value) => { onChangeBookingDate("trip_end_time", value) }}
                                                    renderInput={(params) => <TextField size="small" label='รับเวลา' name='trip_end_time' fullWidth {...params} />}
                                                />
                                            </Grid> */}
                                        </Grid>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </DialogContent >
                <DialogActions>
                    <Button onClick={() => { formik.submitForm() }}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}
