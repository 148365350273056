import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import React, { useEffect, useState } from 'react'
import { Box, Stepper, Step, StepLabel, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, CircularProgress, Backdrop, FormControlLabel } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup';
import WorkTypeDialog from './WorkTypeDialog'
import { fDateTo, fDateZTo } from '../../utils/formatTime'
import CustomerDialog from './CustomerDialog'
import DialogTrip from './DialogTrip'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ButtonDialogWorkUpdate from './ButtonDialogWorkUpdate'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonDialogWork from './ButtonDialogWork'
import TypeCarsDialog from './TypeCarsDialog'
import { Delete } from '@mui/icons-material'
import DialogTripUpdate from './DialogTripUpdate'
import _ from 'lodash'
import { aesApi, aesEncryptECBHex } from '../../query/QueryAES'

export default function BookingUpdate({ userId }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const { id } = useParams()

  const [initialValues, setInitialValues] = useState(null)
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    axios.get(`https://booking.sitgps.com/booking/book_h?booking_id=${id}`)
      .then(res => {
        console.log('res.data?.message', res.data?.message)
        if (res.data?.message === null) {
          navigate("/booking")
        } else {
          setInitialValues({
            ...res.data,
            datetime_end: (res.data?.datetime_end?.toString())?.replaceAll("T", " ")?.replaceAll(".000Z", ""),
            datetime_start: (res.data?.datetime_start?.toString())?.replaceAll("T", " ")?.replaceAll(".000Z", "")
          })
          setLoading(false)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }, [id])



  const handleClickVariant = ({ message, variant }) => {
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };



  const handleSnackWithAction = ({ message, onHandle }) => {
    closeSnackbar()
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: "center",
        vertical: 'bottom'
      },
      variant: 'default',
      // style: { bgcolor: "whitesmoke !important" },
      action: (key) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Button
                size='small'
                color="error"
                variant="contained"
                onClick={() => {
                  closeSnackbar(key)
                  onHandle()
                }}
              >
                <CheckRoundedIcon fontSize="small" />
              </Button>
              <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                <CloseRoundedIcon fontSize="small" />
              </Button>
            </Stack>
          </React.Fragment>
        )
      }
    });
  };

  const FormSchema = Yup.object().shape({
    company_name: Yup.string().required('required'),
    // firstname: Yup.string().when('company_id', {
    //   is: (company_id) => company_id?.length === 0,
    //   then: Yup.string()
    //     .required('required')
    // }),
  });
  const formik = useFormik({
    initialValues: {
      ...initialValues
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onSubmit: (values, { setValues }) => {
      console.log('values', values)
      setLoading(true)
      axios.put(`https://booking.sitgps.com/booking/book_h`, {
        ...values,
        datetime_end: (values.datetime_end)?.replaceAll("T", " ")?.replaceAll(".000Z", ""),
        datetime_start: (values.datetime_start)?.replaceAll("T", " ")?.replaceAll(".000Z", ""),
        "booking_userid": userId
      })
        .then(res => {
          handleClickVariant({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
          funcGetBooking(res.data.booking_id)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  });
  const funcGetBooking = (booking_id) => {
    axios.get(`https://booking.sitgps.com/booking/book_h?booking_id=${booking_id}`)
      .then(res => {
        setLoading(false)
        if (res.data?.message === null) {
          navigate("/booking")
        } else {
          // setInitialValues({
          formik.setValues({
            ...res.data,
            datetime_end: (res.data?.datetime_end?.toString())?.replaceAll("T", " ")?.replaceAll(".000Z", ""),
            datetime_start: (res.data?.datetime_start?.toString())?.replaceAll("T", " ")?.replaceAll(".000Z", "")
          })
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }




  const onChangeType = (d) => {
    formik.setValues({ ...formik.values, ...d })
  }
  const onChangeBookingDate = (name, value) => {
    formik.setFieldValue(name, value)
  }
  const onChangeBookingDateStart = (name, value) => {
    const datebook = value.setHours(0, 0, 0)
    formik.setFieldValue(name, datebook)
  }
  const onChangeBookingDateEnd = (name, value) => {
    const datebook = value.setHours(23, 59, 59)
    formik.setFieldValue(name, datebook)
  }
  const onChangeBookingDateTime = (name, value) => {
    formik.setFieldValue(name, value)
  }
  const onChangeCustomer = (d) => {
    formik.setFieldValue("company_id", d.company_id)
    formik.setFieldValue("company_name", d.company_name)
    formik.setFieldValue("company_note2", d.company_note2)
  }
  const onHandleSubmit = (d) => {
    funcGetBooking(d)
  }
  const onHandleDelD = (d) => {
    const data = {
      "booking_userid": userId,
      "booking_id": d.booking_id,
      "trip_id": d.trip_id,
      "line_id": d.line_id
    }
    axios.delete(`https://booking.sitgps.com/booking/book_d`, { data: data })
      .then(res => {
        handleClickVariant({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
        funcGetBooking(data.booking_id)
      })
  }

  const onHandleDelTrip = (d) => {
    const data = {
      "booking_id": d.booking_id,
      "trip_id": d.trip_id,
      "booking_userid": userId
    }
    axios.delete(`https://booking.sitgps.com/booking/book_trip`, { data: data })
      .then(res => {
        handleClickVariant({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
        funcGetBooking(data.booking_id)
      })
  }


  const onAddTypeCars = () => {
    const data = (JSON.parse(formik.values.vehicle_list))
    formik.setFieldValue("vehicle_list",
      JSON.stringify(data.concat({ "color": "", "seat": "", "fuel": "", "count": "" }))
    )
  }



  const finishBooking = (booking_id) => {
    axios.put(`https://booking.sitgps.com/booking/book_h`, {
      booking_id: booking_id, status_trip: "U", "booking_userid": userId
    })
      .then(res => {
        handleClickVariant({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
        navigate(`../`)
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  if (loading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }



  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ padding: "10px" }}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={1} alternativeLabel>
              <Step>
                <StepLabel>รับเรื่อง</StepLabel>
              </Step>
              <Step>
                <StepLabel>จัดรถ</StepLabel>
              </Step>
              <Step>
                <StepLabel>ยืนยัน</StepLabel>
              </Step>
              <Step>
                <StepLabel>วิ่งงาน</StepLabel>
              </Step>
              <Step>
                <StepLabel>ชำระเงิน</StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Stack spacing={1}>
            <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ใบจอง</Typography></Box>
            <Box>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={1} >
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1} >
                        <Grid item xs={5} md={5}>
                          <TextField size="small" label='กลุ่มประเภท' fullWidth
                            // value={values.booking_type}
                            value={formik.values.type_group}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" >
                                  <WorkTypeDialog onHandle={(d) => { onChangeType(d) }} />
                                </InputAdornment>
                              )
                            }}
                            name='type_group'
                            error={Boolean(formik.touched.type_group && formik.errors.type_group)}
                            helperText={formik.touched.type_group && formik.errors.type_group}
                            {...formik.getFieldProps('type_group')}
                          />
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <TextField size="small" label='ประเภทงาน'
                            name='type_booking'
                            {...formik.getFieldProps('type_booking')}
                            error={Boolean(formik.touched.type_booking && formik.errors.type_booking)}
                            helperText={formik.touched.type_booking && formik.errors.type_booking}
                            fullWidth
                            value={formik.values.type_booking}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextField fullWidth size="small" type="datetime-local" name='datetime_start'  {...formik.getFieldProps('datetime_start')} value={formik.values?.datetime_start} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField fullWidth size="small" type="datetime-local" name='datetime_end'  {...formik.getFieldProps('datetime_end')} value={formik.values?.datetime_end} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Collapse in={true} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={12}>
                                <TextField size="small" label='บริษัท' fullWidth
                                  // InputLabelProps={{ shrink: formCus.name ? true : false }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end" >
                                        <CustomerDialog onHandle={(d) => { onChangeCustomer(d) }} />
                                      </InputAdornment>
                                    )
                                  }}
                                  disabled
                                  value={formik.values.company_name}
                                  name='company_name'
                                  {...formik.getFieldProps("company_name")}
                                  error={Boolean(formik.touched.company_name && formik.errors.company_name)}
                                  helperText={formik.touched.company_name && formik.errors.company_name}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextField size="small" label='เงื่อนไข' name='company_note2' fullWidth
                                  disabled
                                  InputLabelProps={{
                                    shrink: formik.values.company_note2 !== "",
                                  }}
                                  multiline
                                  rows={2}
                                  {...formik.getFieldProps('company_note2')}
                                  value={formik.values.company_note2}
                                />
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControlLabel
                            control={<Checkbox
                              value={checked}
                              onChange={(e) => {
                                setChecked(e.target.checked)
                              }}
                            />}
                            label="ใส่ข้อมูลลูกค้า"
                            labelPlacement="end"
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Collapse in={checked} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <Grid container spacing={1} >
                              <Grid item xs={12} md={2}>
                                <TextField size="small" label='คำนำหน้า' name='prefix' fullWidth
                                  value={formik.values.prefix}
                                  {...formik.getFieldProps("prefix")}
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <TextField
                                  size="small" label='ชื่อ' fullWidth
                                  // InputLabelProps={{ shrink: formCus.name ? true : false }}
                                  // InputProps={{
                                  //   endAdornment: (
                                  //     <InputAdornment position="end" >
                                  //       <CustomerDialog onHandle={(d) => { onChangeCustomer(d) }} />
                                  //     </InputAdornment>
                                  //   )
                                  // }}
                                  {...formik.getFieldProps("firstname")}
                                  error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                                  helperText={formik.touched.firstname && formik.errors.firstname}
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <TextField size="small" label='นามสกุล' fullWidth
                                  value={formik.values.lastname}
                                  name='lastname'
                                  {...formik.getFieldProps("lastname")}
                                  error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                                  helperText={formik.touched.lastname && formik.errors.lastname}
                                />
                              </Grid>
                              <Grid item xs={8} md={8}>
                                <TextField size="small" label='เลขที่ผู้เสียภาษี' name='taxid' fullWidth
                                  value={formik.values.taxid !== null ? formik.values.taxid : ""}
                                  {...formik.getFieldProps("taxid")}
                                />
                              </Grid>
                              <Grid item xs={4} md={4}>
                                <TextField size="small" label='สาขา' name='branch' fullWidth
                                  value={formik.values.branch}
                                  {...formik.getFieldProps("branch")}
                                />
                              </Grid>
                              <Grid item xs={9} md={9}>
                                <TextField size="small" label='ที่อยู่' name='address' fullWidth
                                  value={formik.values.address}
                                  {...formik.getFieldProps("address")}
                                />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <TextField size="small" label='ประเภทที่อยู่' name='address_type' fullWidth
                                  value={formik.values.address_type}
                                  {...formik.getFieldProps("address_type")}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='แขวง/ตำบล' name='subdistrict' fullWidth
                                  value={formik.values.subdistrict}
                                  {...formik.getFieldProps("subdistrict")}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='เขต/อำเภอ' name='district' fullWidth
                                  value={formik.values.district}
                                  {...formik.getFieldProps("district")}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='จังหวัด' name='province' fullWidth
                                  value={formik.values.province}
                                  {...formik.getFieldProps("province")}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='รหัสไปรษณีย์' name='postcode' fullWidth
                                  value={formik.values.postcode}
                                  {...formik.getFieldProps("postcode")}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextField size="small" label='เบอร์ติดต่อ' name='phone' fullWidth
                                  value={formik.values.phone}
                                  {...formik.getFieldProps("phone")}
                                />
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1} >
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='เลขที่อ้างอิง' name='work_ref' fullWidth
                            {...formik.getFieldProps('work_ref')}
                            value={formik.values.work_ref}
                          />
                        </Grid>

                        {/* /////////// */}
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={1} >
                            <Grid item xs={4} md={4}>
                              <TextField size="small" label='salecode' name='salecode' fullWidth
                                {...formik.getFieldProps('salecode')}
                                value={formik.values.salecode}
                              />
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <TextField size="small" label='salename' name='salename' fullWidth
                                {...formik.getFieldProps('salename')}
                                value={formik.values.salename}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="space-between">
                            <Box sx={{ fontWeight: "bold" }}>รายการประเภทรถ</Box>
                            <Box>
                              <Button onClick={onAddTypeCars}>เพิ่ม</Button>
                            </Box>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          {
                            (JSON.parse(formik.values.vehicle_list))?.map((type_vehicle, i) => {
                              return (
                                <Grid container spacing={1} key={`cars-${i}`} sx={{ marginTop: "5px", marginBottom: "5px" }} >
                                  <Grid item xs={8} md={8}>
                                    <TextField size="small" label='ประเภทรถ'
                                      name='vehicle_list.type'
                                      fullWidth
                                      disabled
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end" >
                                            <TypeCarsDialog onHandle={(d) => {
                                              console.log('d', d)
                                              const data = (JSON.parse(formik.values.vehicle_list))
                                              data[i] = { ...type_vehicle, ...d }
                                              formik.setFieldValue("vehicle_list",
                                                JSON.stringify(data)
                                              )
                                            }} />
                                          </InputAdornment>
                                        )
                                      }}
                                      // {...formik.getFieldProps('vehicle_list.type')}
                                      value={`${(type_vehicle)?.color}/${(type_vehicle)?.seat}/${(type_vehicle)?.fuel}`}
                                    />
                                  </Grid>
                                  <Grid item xs={3} md={3}>
                                    <TextField size="small" label='จำนวน'
                                      name='vehicle_list.count'
                                      fullWidth
                                      // {...formik.getFieldProps('vehicle_list.name')} 
                                      onChange={e => {
                                        const data = (JSON.parse(formik.values.vehicle_list))
                                        data[i] = { ...type_vehicle, count: e.target.value }
                                        formik.setFieldValue("vehicle_list",
                                          JSON.stringify(data)
                                        )
                                      }}
                                      value={`${(type_vehicle)?.count}`}
                                    />
                                  </Grid>
                                  <Grid item xs={1} md={1}>
                                    <IconButton size="small"
                                      onClick={
                                        () => {
                                          handleSnackWithAction({
                                            message: "ต้องการลบข้อมูลประเภทรถ", onHandle: () => {
                                              const data = (JSON.parse(formik.values.vehicle_list))
                                              const dd = data.filter((v, di) => { return di !== i })
                                              formik.setFieldValue("vehicle_list",
                                                JSON.stringify(dd)
                                              )
                                            }
                                          })
                                        }

                                        // () => {
                                        //   const data = (JSON.parse(formik.values.vehicle_list))
                                        //   const dd = data.filter((v, di) => { return di !== i })
                                        //   formik.setFieldValue("vehicle_list",
                                        //     JSON.stringify(dd)
                                        //   )
                                        // }
                                      }
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              )
                            })
                          }
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='จำนวนผู้โดยสาร' name='guest_count' fullWidth
                            {...formik.getFieldProps('guest_count')}
                            value={formik.values.guest_count}
                          />
                        </Grid>

                        {/* /////////// */}

                        <Grid item xs={12}>
                          <Box sx={{ marginBottom: "10px" }}>
                            <Typography>ผู้ติดต่อ</Typography>
                          </Box>
                          <Grid container spacing={1}>
                            <Grid item xs={2}>
                              <TextField size="small" label='คำนำหน้า' name='contact_prefix' fullWidth
                                {...formik.getFieldProps('contact_prefix')}
                                value={formik.values.contact_prefix}
                                error={Boolean(formik.touched.contact_prefix && formik.errors.contact_prefix)}
                                helperText={formik.touched.contact_prefix && formik.errors.contact_prefix}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField size="small" label='ชื่อ' name='contact_firstname' fullWidth
                                {...formik.getFieldProps('contact_firstname')}
                                value={formik.values.contact_firstname}
                                error={Boolean(formik.touched.contact_firstname && formik.errors.contact_firstname)}
                                helperText={formik.touched.contact_firstname && formik.errors.contact_firstname}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField size="small" label='นามสกุล' name='contact_lastname' fullWidth
                                {...formik.getFieldProps('contact_lastname')}
                                value={formik.values.contact_lastname}
                                error={Boolean(formik.touched.contact_lastname && formik.errors.contact_lastname)}
                                helperText={formik.touched.contact_lastname && formik.errors.contact_lastname}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='เบอร์โทร' name='contact_phone' fullWidth
                            {...formik.getFieldProps('contact_phone')}
                            value={formik.values.contact_phone}
                            error={Boolean(formik.touched.contact_phone && formik.errors.contact_phone)}
                            helperText={formik.touched.contact_phone && formik.errors.contact_phone}
                          />
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                          <Autocomplete
                            multiple
                            limitTags={10}
                            id="multiple-limit-tags"
                            value={formik.values.contact_req}
                            isOptionEqualToValue={(option, value) => option === value}
                            options={["น้ำดื่ม", "ผ้าเย็น", "ขนม", "ขนม1", "ขนม2", "ขนม3", "ขนม4"]}
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) => {
                              formik.setFieldValue("contact_req", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField size="small" label='รายละเอียดความต้องการ' {...params} sx={{ overFlow: "auto" }} fullWidth />
                            )}
                          />
                        </Grid> */}
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='หมายเหตุ' multiline maxRows={3} minRows={3}
                            value={formik.values.contact_note}
                            {...formik.getFieldProps('contact_note')}
                            name='contact_note' fullWidth />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Stack spacing={1} direction="row">
                            <Button
                              type="submit"
                              variant="outlined">บันทึก</Button>

                            {/* <Button
                              onClick={() => {
                                handleSnackWithAction({
                                  message: "คุณต้องการยืนยันการจัดรถ?", onHandle: () => {
                                    finishBooking(formik.values.booking_id)
                                  }
                                })
                              }}
                              type="button"
                              variant="outlined">ยืนยันการจัดรถ</Button> */}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
              {/* {formik.values.booking_id && ( */}
              <Box sx={{ marginTop: "10px" }}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Divider />
                </Box>
                <Box>
                  <Box sx={{ padding: "10px" }}>
                    <DialogTrip
                      dropoff_date={formik.values.datetime_end?.replaceAll("T", " ")?.replaceAll(".000Z", "")}
                      pickup_date={formik.values.datetime_start?.replaceAll("T", " ")?.replaceAll(".000Z", "")}
                      booking_id={formik.values.booking_id}
                      onHandleSubmit={onHandleSubmit}
                      userId={userId}
                    />
                  </Box>
                  <Box sx={{ minHeight: "250px", height: "400px", borderStyle: "solid", borderColor: "#e5e7eb", borderRadius: "10px", overflow: "auto" }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow >
                          <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                            รหัสทริป
                          </TableCell>
                          <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                            {/* ประเภททริป */}
                            กรุ๊ปโค้ด
                          </TableCell>
                          <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                            วันที่เริ่ม
                          </TableCell>
                          <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                            วันที่สิ้นสุด
                          </TableCell>
                          <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }} colSpan={7}>

                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          formik.values?.trip_data?.map((trip, index) => {
                            return (
                              <React.Fragment key={`TableRow-trip_id-${index}`}>
                                <TableRow key={`trip_id-${index}`} >
                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                    {trip.trip_id}
                                  </TableCell>
                                  {/* <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                    {trip.groupcode}
                                  </TableCell> */}
                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                    {trip.trip_type}
                                  </TableCell>
                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                    {fDateTo((trip.trip_start_datetime)?.replaceAll("T", " ").replaceAll(".000Z", ""), "dd/MM/yy HH:mm")}
                                  </TableCell>
                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                    {fDateTo((trip.trip_end_datetime)?.replaceAll("T", " ").replaceAll(".000Z", ""), "dd/MM/yy HH:mm")}
                                  </TableCell>
                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }} colSpan={7}>
                                    <Stack spacing={1} direction="row" justifyContent="end">
                                      <DialogTripUpdate
                                        booking_id={formik.values.booking_id}
                                        trip_detail={trip}
                                        dropoff_date={formik.values.datetime_end?.replaceAll("T", " ")?.replaceAll(".000Z", "")}
                                        pickup_date={formik.values.datetime_start?.replaceAll("T", " ")?.replaceAll(".000Z", "")}
                                      />
                                      <ButtonDialogWork
                                        dropoff_date={trip.trip_end_datetime?.replaceAll("T", " ")?.replaceAll(".000Z", "")}
                                        pickup_date={trip.trip_start_datetime?.replaceAll("T", " ")?.replaceAll(".000Z", "")}
                                        filter={(JSON.parse(formik.values.vehicle_list))} userId={userId} booking_id={formik.values.booking_id} trip_id={trip.trip_id} station_start_datetime={formik.values.datetime_start} station_end_datetime={formik.values.datetime_end} onHandleSubmit={onHandleSubmit} />
                                      <IconButton onClick={() => {
                                        handleSnackWithAction({
                                          message: "ต้องการลบข้อมูลทริป?", onHandle: () => {
                                            onHandleDelTrip({
                                              "booking_id": formik.values.booking_id,
                                              "trip_id": trip.trip_id
                                            })
                                          }
                                        })
                                      }} size="small"><DeleteOutlineIcon fontSize="inherit" /></IconButton>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                                {
                                  trip?.data?.length > 0 && (
                                    <>
                                      <TableRow >
                                        {/* <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                          ประเภทรถ
                                        </TableCell> */}
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          ทะเบียนรถ
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          พนักงานขับรถ
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          กรุ๊ปโค้ด
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          สถานที่
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          วันที่เริ่ม
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          เที่ยวบิน
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          สถานที่
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          วันที่สิ้นสุด
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          เที่ยวบิน
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                          หมายเหตุ
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>

                                        </TableCell>
                                      </TableRow>
                                      {
                                        _.orderBy(trip.data, ['groupcode'], ['asc']).map((work, index) => {
                                          return (
                                            <TableRow key={`work-${work.line}-${index}`} >
                                              {/* <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                                {work.type_car}
                                              </TableCell> */}
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {work.vehicle_id}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {
                                                  work.driver_id.map((info, index) => {
                                                    return (<EmployeeInfo id={info} key={`EmployeeInfo-${index}`} />)
                                                  })
                                                }
                                                {/* <EmployeeInfo /> */}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {work.groupcode}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {work.station_start}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {fDateTo((work.station_start_datetime).replaceAll("T", " ").replaceAll(".000Z", ""), "dd/MM/yy HH:mm")}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {work.station_start_flight}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {work.station_end}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {fDateTo((work.station_end_datetime).replaceAll("T", " ").replaceAll(".000Z", ""), "dd/MM/yy HH:mm")}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {work.station_end_flight}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                {work.note}
                                              </TableCell>
                                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                <Stack spacing={1} direction="row" justifyContent="end">
                                                  <Stack spacing={1} direction="row" justifyContent="end">
                                                    <ButtonDialogWorkUpdate
                                                      dropoff_date={trip.trip_end_datetime?.replaceAll("T", " ")?.replaceAll(".000Z", "")}
                                                      pickup_date={trip.trip_start_datetime?.replaceAll("T", " ")?.replaceAll(".000Z", "")}
                                                      filter={(JSON.parse(formik.values.vehicle_list))} userId={userId} data={work} booking_id={formik.values.booking_id} trip_id={trip.trip_id} onHandleSubmit={onHandleSubmit} />
                                                  </Stack>
                                                  <IconButton onClick={() => {
                                                    handleSnackWithAction({
                                                      message: "ต้องการลบข้อมูลงาน?", onHandle: () => {
                                                        onHandleDelD(
                                                          {
                                                            "booking_id": formik.values.booking_id,
                                                            "trip_id": trip.trip_id,
                                                            "line_id": work.line_id
                                                          }
                                                        )
                                                      }
                                                    })
                                                  }} size="small"><DeleteOutlineIcon fontSize="inherit" /></IconButton>
                                                </Stack>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        })
                                      }
                                    </>
                                  )
                                }
                              </React.Fragment>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Box>
              {/* )} */}
            </Box>
          </Stack>
        </Box>
      </LocalizationProvider>
    </div>
  )
}


const TagAutocomplete = ({ values, options, onChange }) => {
  const [value, setValue] = useState([])

  useEffect(() => {
    if (values) {
      setValue(values)
    }
  }, [values])

  return (
    <Autocomplete
      multiple
      limitTags={10}
      value={value}
      isOptionEqualToValue={(option, value) => option === value}
      options={options}
      getOptionLabel={(option) => option}
      onChange={onChange}
      renderInput={(params) => (
        <TextField size="small" label='รายละเอียดความต้องการ' {...params} sx={{ overFlow: "auto" }} fullWidth />
      )}
    />
  )
}


const EmployeeInfo = ({ id }) => {
  const [userInfo, setUserInfo] = useState(null)
  useEffect(() => {
    const req = {
      "database": "booking_prd",
      "data": aesEncryptECBHex(`select * from if_employee WHERE userid = '${id}';`)
    }
    aesApi.post("https://sqlquery.sitbooking.com/query", req)
      .then(res => {
        // const d = []
        // d.length
        // console.log('res.data', res.data)
        // setData(res.data)[booking_prd].dbo.[company_id]
        if (res.data?.message === null && res.data?.length !== 1) {
          setUserInfo([])
        } else {
          setUserInfo(...res.data)
        }
      })
      .catch(err => {
        setUserInfo(null)
        console.log('err', err)
      })
  }, [id])


  return (
    <Box>
      {`(${userInfo?.userid}) ${userInfo?.fname_lname} ${userInfo?.phone}`}
      {/* <Grid item xs={12} md={12}>
        <Stack direction="row" spacing={1}>
          <TextField size="small" InputLabelProps={{ shrink: true }} disabled label='userid' placeholder='userid' value={userInfo?.userid} fullWidth />
          <TextField size="small" InputLabelProps={{ shrink: true }} disabled label='name' placeholder='name' value={userInfo?.fname_lname} fullWidth />
          <Button onClick={onClick}>ลบ</Button>
        </Stack>
      </Grid> */}
    </Box>
  )
}