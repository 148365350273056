import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip } from '@mui/material'
import DialogEventsEmployee from '../DialogEventsEmployee';
import DialogViewWork from '../DialogViewWork';
import _ from 'lodash';

export default function TableOverViewEmployee({
    dataFillter, handleEventClick, maxDate, year, month, open, userId
}) {

    const [dataSelect, setDataSelect] = useState("")
    const [dateBetween, setDateBetween] = useState({
        userid: "",
        date_start: "",
        date_end: ""
    });

    const onSelect = ({ userid }) => {
        handleEventClick(userid)
    }




    const getBackgroundColor = (date, userid, ranges) => {

        const event = ranges?.find((event) => {
            const start = new Date(event.date_start);
            const end = new Date(event.date_end);
            const dateToCheck = new Date(date);
            return dateToCheck > start && dateToCheck <= end || event.date_start.split(" ")[0] === date.split(" ")[0]
        }
        );
        return event ? event?.color : (dateBetween.date_start !== "" && dateBetween.userid === userid) ? date >= dateBetween.date_start && date <= (dateBetween.date_end !== "" ? dateBetween.date_end : dateBetween.date_start) ? "#e9e6ee" : 'white' : 'white';
    };



    const filterEventInfo = (date, ranges) => {
        if (ranges.length > 0) {
            return ranges?.filter((event) => {
                const start = new Date(event.date_start);
                const end = new Date(event.date_end);
                const dateToCheck = new Date(date);
                return dateToCheck > start && dateToCheck <= end || event.date_start.split(" ")[0] === date.split(" ")[0]
            })
        } else {
            return []
        }
    };

    const [isOpenEvents, setIsOpenEvents] = useState(false);

    const handleEventsOpen = (data) => {
        setIsOpenEvents(true)
    }
    const handleEventsClose = () => {
        setIsOpenEvents(false)
    }
    const handleEventsCancel = () => {
        setIsOpenEvents(false)
    }

    const [isOpenWork, setIsOpenWork] = useState(false)
    const [dataViewWork, setDataViewWork] = useState([])
    const handleViewWorkClose = () => {
        setIsOpenWork(false)
    }
    const handleEscClose = () => {
        setIsOpenWork(false)
    }
    return (
        <>
            <DialogEventsEmployee userId={userId} open={isOpenEvents} onCancel={handleEventsCancel} onClose={handleEventsClose} data={dateBetween} />
            <DialogViewWork userId={userId} open={isOpenWork} onCloseEsc={handleEscClose} onClose={handleViewWorkClose} data={dataViewWork} />
            {
                dataFillter.map((rowdata, index) => {
                    return (
                        <TableRow key={`car-row-${index}`}>
                            <TableCell sx={{ position: 'sticky', padding: "0px", whiteSpace: "nowrap", left: 0, zIndex: 1302, left: 0, backgroundColor: "white" }}>
                                <Box sx={{ width: "308px", height: "25px" }}>
                                    <BoxNameEmployee fname_lname={rowdata.fname_lname} userid={rowdata.userid} />
                                </Box>
                            </TableCell>
                            {
                                (
                                    [...Array(maxDate)].map((value, index) => {
                                        const day = (index + 1).toString().padStart(2, "0")
                                        const data = filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data)
                                        const dataDate = _.uniqBy(data.map(d => { return { "date_start": d.date_start?.split(" ")[0] } }), "date_start")
                                        return (
                                            <TableCell
                                                key={`car-TableCell-row-${index}`}
                                                onClick={() => {
                                                    if (data.length <= 0) {
                                                        const data = {
                                                            userid: rowdata.userid,
                                                            type_car: rowdata.standard,
                                                            date: `${year}-${month}-${day}`
                                                        }
                                                        onSelect(data)
                                                    } else {
                                                        setIsOpenWork(true)
                                                        setDataViewWork(filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data))
                                                    }
                                                }}
                                                sx={{
                                                    cursor: "pointer",
                                                    position: 'sticky',
                                                    whiteSpace: "nowrap",
                                                    left: 0,
                                                    zIndex: 1301,
                                                    padding: "0px",
                                                    backgroundColor: getBackgroundColor(`${year}-${month}-${day} 00:00:00`, rowdata.userid, rowdata.data),
                                                }}
                                            >
                                                {
                                                    data.length > 0 ? (
                                                        <Box position="relative">
                                                            <Tooltip
                                                                open={(dataDate.filter(d => d.date_start === `${year}-${month}-${day}`).length > 0 && open)}
                                                                title={<BoxName data={data.filter(d => d.date_start.split(" ")[0] === `${year}-${month}-${day}`)} />} placement="right"
                                                                PopperProps={{
                                                                    style: { zIndex: "1300 !important" }
                                                                }}

                                                                slotProps={{
                                                                    popper: {
                                                                        modifiers: [
                                                                            {
                                                                                name: 'offset',
                                                                                options: {
                                                                                    offset: [0, -47],
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                    tooltip: {
                                                                        sx: { userSelect: "none", cursor: "pointer", marginLeft: "1px !important", padding: "0px !important", borderRadius: "2px ", opacity: "0.8", borderColor: "white", border: "1px", borderStyle: "solid" }
                                                                    }
                                                                }}
                                                            >
                                                                <Box sx={{ width: "47.58px", height: "35px" }}>
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                    ) :
                                                        (
                                                            <Box sx={{ width: "47.58px", height: "35px" }}></Box>
                                                        )
                                                }
                                            </TableCell>
                                        )
                                    })
                                )
                            }
                        </TableRow >
                    )
                })
            }
        </>
    )
}

const BoxName = ({ data }) => {
    const calculateDifference = (d) => {
        const start = new Date(d.date_start.split(" ")[0]);
        const end = new Date(d.date_end.split(" ")[0]);
        const differenceInMilliseconds = Math.abs(end - start);
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        return differenceInDays + 1;
    };
    return (
        <>
            <Box sx={{ maxHeight: "25px", overflow: "hidden" }}>
                {data.map((d, i) => {
                    return (
                        <Box sx={{ maxWidth: `${43 * calculateDifference(d)}px`, whiteSpace: "nowrap" }}> {`${d.event_name || d.company_name || d.booking_id}`}</Box>
                    )
                })}
            </Box >
        </>
    )
}


const BoxNameEmployee = ({ userid, fname_lname }) => {

    return (
        <Box sx={{ width: "280px", height: "30px", fontSize: "18px", fontWeight: "40", justifyContent: "center" }}>
            <Box sx={{ paddingTop: "5px" }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography>
                        {`${fname_lname}`}
                    </Typography>
                    <Typography>
                        {`(${userid})`}
                    </Typography>
                </Stack>
            </Box>
        </Box>
    )
}