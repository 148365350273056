import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { aesApi, aesEncryptECBHex } from '../../../query/QueryAES';

export default function SettingDriverDialogUpdate({ onClose, open, getData, data }) {

    const schema = Yup.object().shape({
        fname_lname: Yup.string().required('required')
    });
    // const handleSnackbar = ({ variant, message }) => {
    //     enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
    // };
    const formik = useFormik({
        initialValues: {
            ...data
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            onClose()
            const req = {
                "database": "booking_prd",
                "data": aesEncryptECBHex(`UPDATE if_employee SET userid = '${values.userid}' , fname_lname = '${values.fname_lname}' ,  position = '${values.position}' ,  department = '${values.department}' ,  department2 = '${values.department2}' ,  level = '${values.level}' ,  idcard = '${values.idcard}' ,  bd = '${values.bd}' ,  work_start = '${values.work_start}' ,  probation_date = '${values.probation_date}' ,  address = '${values.address}' ,  sub_district = '${values.sub_district}' ,  district = '${values.district}' ,  province = '${values.province}' ,  post_code = '${values.post_code}' ,  status = '${values.status}' ,  phone = '${values.phone}' WHERE userid = '${values.userid}' ;`)
            }
            aesApi.post("https://sqlquery.sitbooking.com/query", req)
                .then(res => {
                    onClose()
                    getData()
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, submitForm } = formik;


    return (
        <div>
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>สร้างข้อมูล พนักงานขับรถ</DialogTitle>
                <DialogContent>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={1} marginTop={"5px"}>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="userid"
                                        {...getFieldProps('userid')}
                                        disabled={true}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ชื่อ-นามสกุล"
                                        {...getFieldProps('fname_lname')}
                                        error={Boolean(touched.fname_lname && errors.fname_lname)}
                                        helperText={touched.fname_lname && errors.fname_lname}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ตำแหน่ง"
                                        {...getFieldProps('position')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="แผนก"
                                        {...getFieldProps('department')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ฝ่าย"
                                        {...getFieldProps('department2')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ระดับ"
                                        {...getFieldProps('level')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="idcard"
                                        {...getFieldProps('idcard')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="bd"
                                        {...getFieldProps('bd')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เริ่มงาน"
                                        {...getFieldProps('work_start')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="วันทดลองงาน"
                                        {...getFieldProps('probation_date')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ที่อยู่"
                                        {...getFieldProps('address')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ตำบล"
                                        {...getFieldProps('sub_district')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="อำเภอ"
                                        {...getFieldProps('district')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="จังหวัด"
                                        {...getFieldProps('province')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="รหัสไปรษณีย์"
                                        {...getFieldProps('post_code')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="สถานะ"
                                        {...getFieldProps('status')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เบอร์โทร"
                                        {...getFieldProps('phone')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForm} variant="outlined">Save</Button>
                    <Button onClick={onClose} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
