import React from 'react'
import { Box, Card, CardContent, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function BookingSettings() {
    const navigation = useNavigate()

    const onClickNev = (e) => {
        navigation(e)

    }

    return (
        <Box padding={"10px"}>
            <Card>
                <CardContent>
                    <Stack spacing={1} margin={"10px"} direction="row">
                        <Button variant="outlined" onClick={() => { onClickNev("events") }}>
                            <Typography >ประเภท Events</Typography>
                        </Button>
                        <Button variant="outlined" onClick={() => { onClickNev("companys") }}>
                            <Typography >ลูกค้า</Typography>
                        </Button>
                        <Button variant="outlined" onClick={() => { onClickNev("cars") }}>
                            <Typography >รายการรถ</Typography>
                        </Button>
                        <Button variant="outlined" onClick={() => { onClickNev("driver") }}>
                            <Typography >พนักงานขับรถ</Typography>
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
        </Box >
    )
}
