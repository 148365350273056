import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import React, { useEffect, useState } from 'react'
import { Box, Stepper, Step, StepLabel, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, FormControlLabel } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup';
import WorkTypeDialog from './WorkTypeDialog'
import { fDateTo } from '../../utils/formatTime'
import CustomerDialog from './CustomerDialog'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate, useParams } from 'react-router-dom'
import TypeCarsDialog from './TypeCarsDialog'
import { Delete } from '@mui/icons-material'

export default function BookingCreate({ userId }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams()
  const navigate = useNavigate()

  const date = new Date()

  const initialValues = {
    "booking_userid": userId,
    "type_group": "",
    "type_booking": "",
    "datetime_start": `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate() + 1).toString().padStart(2, "0")} 00:00`,
    "datetime_end": `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate() + 1).toString().padStart(2, "0")} 23:59`,
    "type_address": "",
    "prefix": "",
    "firstname": "",
    "company_id": "",
    "company_name": "",
    "lastname": "",
    "taxid": "",
    "branch": "",
    "address": "",
    "subdistrict": "",
    "district": "",
    "province": "",
    "postcode": "",
    "phone": "",
    "work_ref": "",
    "vehicle_list": '[{"color": "","seat": "","fuel": "","count": "" }]',
    "guest_count": "",
    "salecode": "",
    "salename": "",
    "company_note2": "",
    "contact_prefix": "",
    "contact_firstname": "",
    "contact_lastname": "",
    "contact_phone": "",
    "contact_req": "",
    "contact_note": "",
    "status_payment": "",
    "status_trip": "",
    "trip_data": []
  }

  const [checked, setChecked] = useState(false)

  const handleClickVariant = ({ message, variant }) => {
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
  };



  const handleSnackWithAction = ({ message, onHandle }) => {
    closeSnackbar()
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: "center",
        vertical: 'bottom'
      },
      variant: 'default',
      // style: { bgcolor: "whitesmoke !important" },
      action: (key) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Button
                size='small'
                color="error"
                variant="contained"
                onClick={() => {
                  closeSnackbar(key)
                  onHandle()
                }}
              >
                <CheckRoundedIcon fontSize="small" />
              </Button>
              <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                <CloseRoundedIcon fontSize="small" />
              </Button>
            </Stack>
          </React.Fragment>
        )
      }
    });
  };

  const FormSchema = Yup.object().shape({
    company_name: Yup.string().required('required'),
    // firstname: Yup.string().when('company_id', {
    //   is: (company_id) => company_id?.length !== 0,
    //   then: Yup.string()
    //     .required('required')
    // }),
  });
  const formik = useFormik({
    initialValues: {
      ...initialValues
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onSubmit: (values, { setValues }) => {
      const data_req = {
        ...values,
        datetime_start: values.datetime_start.replaceAll("T", " "),
        datetime_end: values.datetime_end.replaceAll("T", " ")
      }
      axios.post(`https://booking.sitgps.com/booking/book_h`,
        {
          ...values,
          datetime_start: values.datetime_start.replaceAll("T", " "),
          datetime_end: values.datetime_end.replaceAll("T", " ")
        })
        .then(res => {
          handleClickVariant({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
          // funcGetBooking(res.data.booking_id)
          navigate(`../update/${res.data.booking_id}`)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  });


  const onAddTypeCars = () => {
    const data = (JSON.parse(formik.values.vehicle_list))
    formik.setFieldValue("vehicle_list",
      JSON.stringify(data.concat({ "color": "", "seat": "", "fuel": "", "count": "" }))
    )
  }

  const funcGetBooking = (booking_id) => {
    axios.get(`https://booking.sitgps.com/booking/book_h?booking_id=${booking_id}`)
      .then(res => {
        formik.setValues(res.data)
      })
      .catch(err => {
        console.log('err', err)
      })
  }


  const onChangeType = (d) => {
    formik.setValues({ ...formik.values, ...d })
  }
  const onChangeBookingDate = (name, value) => {
    formik.setFieldValue(name, value)
  }
  const onChangeBookingDateStart = (name, value) => {
    const datebook = value.setHours(0, 0, 0)
    formik.setFieldValue(name, datebook)
  }
  const onChangeBookingDateEnd = (name, value) => {
    const datebook = value.setHours(23, 59, 59)
    formik.setFieldValue(name, datebook)
  }
  const onChangeBookingDateTime = (name, value) => {
    formik.setFieldValue(name, value)
  }
  const onChangeCustomer = (d) => {
    formik.setFieldValue("company_id", d.company_id)
    formik.setFieldValue("company_name", d.company_name)
    formik.setFieldValue("company_note2", d.company_note2)
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ padding: "10px" }}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={0} alternativeLabel>
              <Step>
                <StepLabel>รับเรื่อง</StepLabel>
              </Step>
              <Step>
                <StepLabel>จัดรถ</StepLabel>
              </Step>
              <Step>
                <StepLabel>ยืนยัน</StepLabel>
              </Step>
              <Step>
                <StepLabel>วิ่งงาน</StepLabel>
              </Step>
              <Step>
                <StepLabel>ชำระเงิน</StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Stack spacing={1}>
            <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ใบจอง</Typography></Box>
            <Box>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={1} >
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1} >
                        <Grid item xs={5} md={5}>
                          <TextField size="small" label='กลุ่มประเภท' fullWidth
                            // value={values.booking_type}
                            value={formik.values.type_group}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" >
                                  <WorkTypeDialog onHandle={(d) => { onChangeType(d) }} />
                                </InputAdornment>
                              )
                            }}
                            name='type_group'
                            error={Boolean(formik.touched.type_group && formik.errors.type_group)}
                            helperText={formik.touched.type_group && formik.errors.type_group}
                            {...formik.getFieldProps('type_group')}
                          />
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <TextField size="small" label='ประเภทงาน'
                            name='type_booking'
                            {...formik.getFieldProps('type_booking')}
                            error={Boolean(formik.touched.type_booking && formik.errors.type_booking)}
                            helperText={formik.touched.type_booking && formik.errors.type_booking}
                            fullWidth
                            value={formik.values.type_booking}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextField fullWidth size="small" type="datetime-local" name='datetime_start'  {...formik.getFieldProps('datetime_start')} value={formik.values?.datetime_start} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField fullWidth size="small" type="datetime-local" name='datetime_end'  {...formik.getFieldProps('datetime_end')} value={formik.values?.datetime_end} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Collapse in={true} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={12}>
                                <TextField size="small" label='บริษัท' fullWidth
                                  // InputLabelProps={{ shrink: formCus.name ? true : false }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end" >
                                        <CustomerDialog onHandle={(d) => { onChangeCustomer(d) }} />
                                      </InputAdornment>
                                    )
                                  }}
                                  disabled
                                  value={formik.values.company_name}
                                  name='company_name'
                                  {...formik.getFieldProps("company_name")}
                                  error={Boolean(formik.touched.company_name && formik.errors.company_name)}
                                  helperText={formik.touched.company_name && formik.errors.company_name}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextField size="small" label='เงื่อนไข' name='company_note2' fullWidth
                                  disabled
                                  InputLabelProps={{
                                    shrink: formik.values.company_note2 !== "",
                                  }}
                                  multiline
                                  rows={2}
                                  {...formik.getFieldProps('company_note2')}
                                  value={formik.values.company_note2}
                                />
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControlLabel
                            control={<Checkbox
                              value={checked}
                              onChange={(e) => {
                                setChecked(e.target.checked)
                              }}
                            />}
                            label="ใส่ข้อมูลลูกค้า"
                            labelPlacement="end"
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Collapse in={checked} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <Grid container spacing={1} >
                              <Grid item xs={12} md={2}>
                                <TextField size="small" label='คำนำหน้า' name='prefix' fullWidth
                                  value={formik.values.prefix}
                                  {...formik.getFieldProps("prefix")}
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <TextField size="small" label='ชื่อ' fullWidth
                                  // InputLabelProps={{ shrink: formCus.name ? true : false }}
                                  // InputProps={{
                                  //   endAdornment: (
                                  //     <InputAdornment position="end" >
                                  //       <CustomerDialog onHandle={(d) => { onChangeCustomer(d) }} />
                                  //     </InputAdornment>
                                  //   )
                                  // }}
                                  // name='firstname'
                                  {...formik.getFieldProps("firstname")}
                                  error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                                  helperText={formik.touched.firstname && formik.errors.firstname}
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <TextField size="small" label='นามสกุล' fullWidth
                                  value={formik.values.lastname}
                                  name='lastname'
                                  {...formik.getFieldProps("lastname")}
                                  error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                                  helperText={formik.touched.lastname && formik.errors.lastname}
                                />
                              </Grid>
                              <Grid item xs={8} md={8}>
                                <TextField size="small" label='เลขที่ผู้เสียภาษี' name='taxid' fullWidth
                                  value={formik.values.taxid !== null ? formik.values.taxid : ""}
                                  {...formik.getFieldProps("taxid")}
                                />
                              </Grid>
                              <Grid item xs={4} md={4}>
                                <TextField size="small" label='สาขา' name='branch' fullWidth
                                  value={formik.values.branch}
                                  {...formik.getFieldProps("branch")}
                                />
                              </Grid>
                              <Grid item xs={9} md={9}>
                                <TextField size="small" label='ที่อยู่' name='address' fullWidth
                                  value={formik.values.address}
                                  {...formik.getFieldProps("address")}
                                />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <TextField size="small" label='ประเภทที่อยู่' name='address_type' fullWidth
                                  value={formik.values.address_type}
                                  {...formik.getFieldProps("address_type")}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='แขวง/ตำบล' name='subdistrict' fullWidth
                                  value={formik.values.subdistrict}
                                  {...formik.getFieldProps("subdistrict")}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='เขต/อำเภอ' name='district' fullWidth
                                  value={formik.values.district}
                                  {...formik.getFieldProps("district")}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='จังหวัด' name='province' fullWidth
                                  value={formik.values.province}
                                  {...formik.getFieldProps("province")}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='รหัสไปรษณีย์' name='postcode' fullWidth
                                  value={formik.values.postcode}
                                  {...formik.getFieldProps("postcode")}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextField size="small" label='เบอร์ติดต่อ' name='phone' fullWidth
                                  value={formik.values.phone}
                                  {...formik.getFieldProps("phone")}
                                />
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1} >
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='เลขที่อ้างอิง' name='work_ref' fullWidth
                            {...formik.getFieldProps('work_ref')}
                            value={formik.values.work_ref}
                          />
                        </Grid>

                        {/* /////////// */}
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={1} >
                            <Grid item xs={4} md={4}>
                              <TextField size="small" label='salecode' name='salecode' fullWidth
                                {...formik.getFieldProps('salecode')}
                                value={formik.values.salecode}
                              />
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <TextField size="small" label='salename' name='salename' fullWidth
                                {...formik.getFieldProps('salename')}
                                value={formik.values.salename}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="space-between">
                            <Box sx={{ fontWeight: "bold" }}>รายการประเภทรถ</Box>
                            <Box>
                              <Button onClick={onAddTypeCars}>เพิ่ม</Button>
                            </Box>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          {
                            (JSON.parse(formik.values.vehicle_list))?.map((type_vehicle, i) => {
                              return (
                                <Grid container spacing={1} key={`car-${i}`} sx={{ marginTop: "5px", marginBottom: "5px" }}>
                                  <Grid item xs={8} md={8}>
                                    <TextField size="small" label='ประเภทรถ'
                                      name='vehicle_list.type'
                                      fullWidth
                                      disabled
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end" >
                                            <TypeCarsDialog onHandle={(d) => {
                                              const data = (JSON.parse(formik.values.vehicle_list))
                                              data[i] = { ...type_vehicle, ...d }
                                              formik.setFieldValue("vehicle_list",
                                                JSON.stringify(data)
                                              )
                                            }} />
                                          </InputAdornment>
                                        )
                                      }}
                                      // {...formik.getFieldProps('vehicle_list.type')}
                                      value={`${(type_vehicle)?.color}/${(type_vehicle)?.seat}/${(type_vehicle)?.fuel}`}
                                    />
                                  </Grid>
                                  <Grid item xs={3} md={3}>
                                    <TextField size="small" label='จำนวน'
                                      name='vehicle_list.count'
                                      fullWidth
                                      // {...formik.getFieldProps('vehicle_list.name')} 
                                      onChange={e => {
                                        const data = (JSON.parse(formik.values.vehicle_list))
                                        data[i] = { ...type_vehicle, count: e.target.value }
                                        formik.setFieldValue("vehicle_list",
                                          JSON.stringify(data)
                                        )
                                      }}
                                      value={`${(type_vehicle)?.count}`}
                                    />
                                  </Grid>
                                  <Grid item xs={1} md={1}>
                                    <IconButton size="small"
                                      onClick={() => {
                                        const data = (JSON.parse(formik.values.vehicle_list))
                                        const dd = data.filter((v, di) => { return di !== i })
                                        formik.setFieldValue("vehicle_list",
                                          JSON.stringify(dd)
                                        )
                                      }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              )
                            })
                          }
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='จำนวนผู้โดยสาร' name='guest_count' fullWidth
                            {...formik.getFieldProps('guest_count')}
                            value={formik.values.guest_count}
                          />
                        </Grid>
                        {/* /////////// */}
                        <Grid item xs={12}>
                          <Box sx={{ marginBottom: "10px" }}>
                            <Typography>ผู้ติดต่อ</Typography>
                          </Box>
                          <Grid container spacing={1}>
                            <Grid item xs={2}>
                              <TextField size="small" label='คำนำหน้า' name='contact_prefix' fullWidth
                                {...formik.getFieldProps('contact_prefix')}
                                value={formik.values.contact_prefix}
                                error={Boolean(formik.touched.contact_prefix && formik.errors.contact_prefix)}
                                helperText={formik.touched.contact_prefix && formik.errors.contact_prefix}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField size="small" label='ชื่อ' name='contact_firstname' fullWidth
                                {...formik.getFieldProps('contact_firstname')}
                                value={formik.values.contact_firstname}
                                error={Boolean(formik.touched.contact_firstname && formik.errors.contact_firstname)}
                                helperText={formik.touched.contact_firstname && formik.errors.contact_firstname}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField size="small" label='นามสกุล' name='contact_lastname' fullWidth
                                {...formik.getFieldProps('contact_lastname')}
                                value={formik.values.contact_lastname}
                                error={Boolean(formik.touched.contact_lastname && formik.errors.contact_lastname)}
                                helperText={formik.touched.contact_lastname && formik.errors.contact_lastname}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='เบอร์โทร' name='contact_phone' fullWidth
                            {...formik.getFieldProps('contact_phone')}
                            value={formik.values.contact_phone}
                            error={Boolean(formik.touched.contact_phone && formik.errors.contact_phone)}
                            helperText={formik.touched.contact_phone && formik.errors.contact_phone}
                          />
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                          <Autocomplete
                            multiple
                            limitTags={10}
                            id="multiple-limit-tags"
                            value={formik.values.contact_req}
                            isOptionEqualToValue={(option, value) => option === value}
                            options={["น้ำดื่ม", "ผ้าเย็น", "ขนม", "ขนม1", "ขนม2", "ขนม3", "ขนม4"]}
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) => {
                              formik.setFieldValue("contact_req", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField size="small" label='รายละเอียดความต้องการ' {...params} sx={{ overFlow: "auto" }} fullWidth />
                            )}
                          />
                        </Grid> */}
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='หมายเหตุ' multiline maxRows={3} minRows={3}
                            value={formik.values.contact_note}
                            {...formik.getFieldProps('contact_note')}
                            name='contact_note' fullWidth />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Button
                            type="submit"
                            variant="outlined">บันทึก</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Box>
          </Stack>
        </Box>
      </LocalizationProvider>
    </div>
  )
}
