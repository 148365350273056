import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { aesApi, aesEncryptECBHex } from '../../../query/QueryAES';

export default function SettingDriverDialogCreate({ onClose, open, getData }) {

    const schema = Yup.object().shape({
        userid: Yup.string().required('required'),
        fname_lname: Yup.string().required('required')
    });
    // const handleSnackbar = ({ variant, message }) => {
    //     enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
    // };
    const formik = useFormik({
        initialValues: {
            userid: "",
            fname_lname: "",
            position: "",
            department: "",
            department2: "",
            level: "",
            idcard: "",
            bd: "",
            work_start: "",
            probation_date: "",
            address: "",
            sub_district: "",
            district: "",
            province: "",
            post_code: "",
            status: "",
            phone: ""
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            // onClose()
            const req = {
                "database": "booking_prd",
                "data": aesEncryptECBHex(`INSERT INTO if_employee(userid,fname_lname,position,department,department2,level,idcard,bd,work_start,probation_date,address,sub_district,district,province,post_code,status,phone)VALUES('${values.userid}','${values.fname_lname}','${values.position}','${values.department}','${values.department2}','${values.level}','${values.idcard}','${values.bd}','${values.work_start}','${values.probation_date}','${values.address}','${values.sub_district}','${values.district}','${values.province}','${values.post_code}','${values.status}','${values.phone}');`)
            }
            aesApi.post("https://sqlquery.sitbooking.com/query", req)
                .then(res => {
                    onClose()
                    getData()
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, submitForm, setFieldValue } = formik;


    return (
        <div>
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>สร้างข้อมูล พนักงานขับรถ</DialogTitle>
                <DialogContent>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={1} marginTop={"5px"}>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="รหัสพนักงาน"
                                        {...getFieldProps('userid')}
                                        error={Boolean(touched.userid && errors.userid)}
                                        helperText={touched.userid && errors.userid}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ชื่อ-นามสกุล"
                                        {...getFieldProps('fname_lname')}
                                        error={Boolean(touched.fname_lname && errors.fname_lname)}
                                        helperText={touched.fname_lname && errors.fname_lname}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ตำแหน่ง"
                                        {...getFieldProps('position')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="แผนก"
                                        {...getFieldProps('department')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ฝ่าย"
                                        {...getFieldProps('department2')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ระดับ"
                                        {...getFieldProps('level')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="idcard"
                                        {...getFieldProps('idcard')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="bd"
                                        {...getFieldProps('bd')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เริ่มงาน"
                                        {...getFieldProps('work_start')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="วันทดลองงาน"
                                        {...getFieldProps('probation_date')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ที่อยู่"
                                        {...getFieldProps('address')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ตำบล"
                                        {...getFieldProps('sub_district')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="อำเภอ"
                                        {...getFieldProps('district')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="จังหวัด"
                                        {...getFieldProps('province')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="รหัสไปรษณีย์"
                                        {...getFieldProps('post_code')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="สถานะ"
                                        {...getFieldProps('status')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เบอร์โทร"
                                        {...getFieldProps('phone')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForm} variant="outlined">Save</Button>
                    <Button onClick={onClose} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
