import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { aesApi, aesEncryptECBHex } from '../../../query/QueryAES';

export default function SettingCarsDialogUpdate({ onClose, open, getData, data }) {

    const schema = Yup.object().shape({

    });
    // const handleSnackbar = ({ variant, message }) => {
    //     enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
    // };
    const formik = useFormik({
        initialValues: {
            ...data
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            onClose()
            const req = {
                "database": "booking_prd",
                "data": aesEncryptECBHex(`UPDATE if_vehicle SET vehicle_id = '${values.vehicle_id}' , brand = '${values.brand}' ,  vehicle_number = '${values.vehicle_number}' ,  cassie_position = '${values.cassie_position}' ,  engine_number = '${values.engine_number}' ,  owner = '${values.owner}' ,  business = '${values.business}' ,  datetime = '${values.datetime}' ,  detail = '${values.detail}' ,  color = '${values.color}' ,  seat = '${values.seat}' ,  size = '${values.size}' ,  standard = '${values.standard}' ,  fuel = '${values.fuel}' ,  tax_last_datetime = '${values.tax_last_datetime}' ,  condition = '${values.condition}' ,  tax = '${values.tax}' ,  lat = '${values.lat}' ,  lng = '${values.lng}' ,  status = '${values.status}' ,  driver_id = '${values.driver_id}' WHERE no = '${values.no}' ;`)
            }
            aesApi.post("https://sqlquery.sitbooking.com/query", req)
                .then(res => {
                    onClose()
                    getData()
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, submitForm } = formik;


    return (
        <div>
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>แก้ไขข้อมูลรถ</DialogTitle>
                <DialogContent>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={1} marginTop={"5px"}>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ทะเบียนรถ"
                                        {...getFieldProps('vehicle_id')}
                                        error={Boolean(touched.vehicle_id && errors.vehicle_id)}
                                        helperText={touched.vehicle_id && errors.vehicle_id}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ยี่ห้อ"
                                        {...getFieldProps('brand')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เลขตัวรถ"
                                        {...getFieldProps('vehicle_number')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ตำแหน่งคัสซี"
                                        {...getFieldProps('cassie_position')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เลขเครื่องยนต์"
                                        {...getFieldProps('engine_number')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ผู้ถือกรรมสิทธิ์"
                                        {...getFieldProps('owner')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ประกอบการ"
                                        {...getFieldProps('business')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="วันจดทะเบียน"
                                        {...getFieldProps('detail')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="สี"
                                        {...getFieldProps('color')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ที่นั่ง"
                                        {...getFieldProps('seat')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ขนาดรถ"
                                        {...getFieldProps('size')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="มาตราฐาน"
                                        {...getFieldProps('standard')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เชื้อเพลิง"
                                        {...getFieldProps('fuel')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="วันครบกำหนดเสียภาษี"
                                        {...getFieldProps('tax_last_datetime')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เงื่อนไข"
                                        {...getFieldProps('condition')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ค่าภาษี"
                                        {...getFieldProps('tax')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="รหัสพนักงานขับประจำ"
                                        {...getFieldProps('driver_id')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="สถานะ"
                                        {...getFieldProps('status')}
                                    // error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                    // helperText={touched.gpsmodel && errors.gpsmodel}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForm} variant="outlined">Save</Button>
                    <Button onClick={onClose} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
