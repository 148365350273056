import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Api } from '../../components/api';
import axios from 'axios';

const initialForm = {}
const initial = []

export default function CustomerDialog({ disabled, onHandle }) {
    const [formState, setFormState] = useState(initialForm)



    const [customers, setCustomers] = useState([])
    const [search, setSearch] = useState("")
    // useEffect(() => {
    //     Api.get("/v0/if/ContactByContype?contype=23")
    //         .then(res => {
    //             setCustomers(res.data)
    //         })
    // }, [])
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
        axios.get(`https://booking.sitgps.com/booking/if_company`)
            .then(res => {
                setCustomers(res.data)
            })
    }
    const handleClose = () => {
        setOpen(false)
        setFormState(initial)
        setSearch("")
    }
    const checkundefined = (data) => {
        if (data === undefined) {
            return ""
        }
        if (data === null) {
            return ""
        }
        return data
    }
    const handleSave = (customer) => {
        setOpen(false)
        onHandle({
            "company_id": checkundefined(customer?.id),
            "company_name": checkundefined(customer?.name),
            "company_note2": checkundefined(customer?.note2),
            // "lastname": checkundefined(customer?.lastname),
            // "prefix": checkundefined(customer?.prefixid),
            // "firstname": checkundefined(customer?.firstname),
            // "lastname": checkundefined(customer?.lastname),
            // "address": checkundefined(address?.addno),
            // "address_type": "",
            // "branch": "",
            // "district": checkundefined(address?.district),
            // "subdistrict": checkundefined(address?.zone),
            // "province": checkundefined(address?.province),
            // "postcode": checkundefined(address?.postcode),
            // "taxid": checkundefined(customer?.taxid),
            // "phone": checkundefined(customer?.phone),
        })
        setFormState(initial)

        // Api.get(`/v0/if/Address?id=${customer.id}&addcode=0`)
        //     .then(res => {
        //         const address = res.data[0]
        //         console.log('address', address)
        //         setOpen(false)
        //         onHandle({
        //             "prefix": checkundefined(customer?.prefixid),
        //             "firstname": checkundefined(customer?.firstname),
        //             "lastname": checkundefined(customer?.lastname),
        //             "address": checkundefined(address?.addno),
        //             "address_type": "",
        //             "branch": "",
        //             "district": checkundefined(address?.district),
        //             "subdistrict": checkundefined(address?.zone),
        //             "province": checkundefined(address?.province),
        //             "postcode": checkundefined(address?.postcode),
        //             "taxid": checkundefined(customer?.taxid),
        //             "phone": checkundefined(customer?.phone),
        //         })
        //         setFormState(initial)
        //     })
    }
    return (
        <Box>
            <IconButton disabled={disabled} onClick={handleOpen} size="small"><SearchIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1}>
                        <Box>
                            <TextField placeholder="ค้นหาลูกค้า" size="small"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </Box>
                    </Stack>

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box component="form" >
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">CODE</TableCell>
                                        <TableCell align="left">ชื่อ</TableCell>
                                        <TableCell align="left">note</TableCell>
                                        {/* <TableCell align="left"></TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {customers.filter(c => c?.code?.toUpperCase().includes(search.toUpperCase()) || c?.name?.toUpperCase().includes(search.toUpperCase())).map((customer) => (
                                        <TableRow
                                            key={customer.id}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                handleSave(customer)
                                            }}
                                        >
                                            <TableCell>
                                                {customer.code}
                                            </TableCell>
                                            <TableCell>
                                                {customer.name}
                                            </TableCell>
                                            {/* <TableCell align="left">{customer.note}</TableCell> */}
                                            <TableCell align="left">{customer.note2}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </DialogContent >
                <DialogActions>
                    {/* <Button onClick={handleSave}>บันทึก</Button> */}
                </DialogActions>
            </Dialog >
        </Box >
    )
}