import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/main';
import NoneLayout from './layouts/NoneLayout';
import ReportLayout from './layouts/ReportLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
// import User from './pages/User';
// import UserNew from './pages/UserNew';
import PrivateRoute from './components/PrivateRoute';
import Map from './pages/map/Map';
import Device from './pages/Device';
import DeviceNew from './pages/DeviceNew';
import DeviceEdit from './pages/DeviceEdit';
import DeviceToDLT from './pages/DeviceToDLT';
import Log from './pages/Log';
import Home from './pages/Home';
import Replay from './pages/route/Replay';
import SettingsLayout from './layouts/SettingsLayout';
import SettingsProfile from './pages/profile/SettingsProfile';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { userAtom } from './components/_state';
import AccountLayout from './layouts/AccountLayout';
import MainDevice from './pages/devices/MainDevice';
import Geofences from './pages/geofences/Geofences';
import GeofencesForm from './pages/geofences/GeofencesForm';
import EditDevice from './pages/devices/EditDevice 1';
import NewDevice from './pages/devices/NewDevice';
import { UseAesCbc } from './components/_crypto/AesCbc';
import { dltVenderAtom, listModelAtom, listProvinceAtom, listVehicleRegisterTypeAtom, listVehicleTypeAtom, permissionAtom } from './components/_state/users';
import ChangepassProfile from './pages/profile/ChangepassProfile';
import GeofencesNew from './pages/geofences/GeofencesNew';
import MainAccount from './pages/accounts/MainAccount';
import NewAccount from './pages/accounts/NewAccount';
import EditAccount from './pages/accounts/EditAccount';
import EditDeviceMain from './pages/devices/EditDeviceMain';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Dlt from './pages/Dlt';
import PrintDltCer from './pages/dlt/PrintDltCer';
import DltCer from './pages/dlt/DltCer';
import MainChart from './pages/chart/MainChart';
import { Typography } from '@mui/material';
import SpeedChart from './pages/chart/SpeedChart';
import EventMain from './pages/event/EventMain';
import OverspeedByDevice from './pages/event/OverspeedByDevice';
import OverspeedByGroup from './pages/event/OverspeedByGroup';
import MainRoutes from './pages/settingsRoute/MainRoute';
import MainRouteTrips from './pages/settingsRoute/MainRouteTrips';
import NewRouteTrips from './pages/settingsRoute/NewRouteTrips';
import UpdateRouteTrips from './pages/settingsRoute/UpdateRouteTrips';
import RouteMain from './pages/settings_route/RouteMain';
import RouteStation from './pages/settings_route/RouteStation';
import DashboardGroup from './pages/dashboard_group/DashboardGroup';
import DashboardMapGroup from './pages/dashboard_group/DashboardMapGroup';
import CardSwipe from './pages/event/CardSwipe';
import CardSwipeByGroup from './pages/event/CardSwipeByGroup';
import Events from './pages/route/Events';
import Groups from './groups/Groups';
import PrintDltCerByChassis from './pages/devices/PrintDltCerByChassis';
import OverspeedByDate from './pages/event/OverspeedByDate';
import CardSwipeTotalByEvents from './pages/event/CardSwipeTotalByEvents';
import OdometerByEvents from './pages/event/OdometerByEvents';
import LogEvents from './pages/event/LogEvents';
import SpeedChartMonth from './pages/chart/SpeedChartMonth';
import OverspeedByDateTwo from './pages/event/OverspeedByDateTwo';
import { listTablesAtom } from './pages/route/markerAtom';
import PoiLogByDevice from './pages/event/PoiLogByDevice';
import MainDashboards from './pages/test/MainDashboard';
import MainDashboard from './pages/NewHome/MainDashboard';
import TheNewMain from './pages/TheNew/TheNewMain';
import PoiLogByGroup from './pages/event/PoiLogByGroup';
import LogEventsByGroup from './pages/event/LogEventsByGroup';
import MainWork from './pages/work/MainWork';
import MainPermission from './pages/permission/MainPermission';
import PermissionsGate from './pages/permission/PermissionsGate';
import { PERMISSIONSPAGE, ROLESPAGE, SCOPES, SCOPESPAGE } from './pages/permission/permission-maps';
import { ACCOUNTS_PERMISSION, SCOPES_CRUD } from './pages/permission/SCOPESPAGES';
import { usePermission } from './pages/permission/fnPermission';
import MainPoi from './pages/poi/MainPoi';
import MainPoiX from './pages/poi_x/MainPoi';

import PrintDltCerDate from './pages/dlt/PrintDltCerDate';
import MapGl from './pages/mapGl/MapGl';
import EvSummary from './pages/summary/EvSummary';
import MainDrivers from './pages/drivers/MainDrivers';
import NewDriver from './pages/drivers/NewDriver';


import HistoryMap from './pages/history/HistoryMap';
import AccountForm from './pages/accountsForm/AccountForm';
import CustomerForm from './pages/accountsForm/CustomerForm';
import DriverForm from './pages/accountsForm/DriverForm';
import GuideForm from './pages/accountsForm/GuideForm';
import Offlinedevice from './pages/event/Offlinedevice';
// import VTwoTest from './pages/accountsForm/VTwoTest';
// import CreateBooking from './pages/accountsForm/CreateBooking';
// import UpdateBooking from './pages/accountsForm/UpdateBooking';
// import WorkType from './pages/accountsForm/work/WorkType';
// import CreateWorkType from './pages/accountsForm/work/CreateWorkType';
// import SubWorkType from './pages/accountsForm/work/SubWorkType';
// import CarsType from './pages/accountsForm/cars/CarsType';
// import Customer from './pages/accountsForm/customers/Customer';
import Contypes from './pages/accountsForm/contypes/Contypes';
import TMainAccounts from './pages/testaccounts/TMainAccounts';
import Concode from './pages/accountsForm/concode/Concode';
import AccountsByConType from './pages/testaccounts/AccountsByConType';

// Booking



// import MainBooking from './pages/mainbooking/MainBooking';
// import CreateBooking from './pages/mainbooking/CreateBooking';
// import UpdateBooking from './pages/mainbooking/UpdateBooking';
import MainWorkByUser from './pages/work/MainWorkByUser';
import Prefixs from './pages/accountsForm/prefixs/Prefixs';
import Company from './pages/accountsForm/company/Company';
import Sexs from './pages/accountsForm/sexs/Sexs';
import Admin from './pages/accountsForm/admins/Admin';
import GroupPermission from './pages/accountsForm/grouppermission/GroupPermission';
import RouterHasPermission from './haspermission/RouterHasPermission';
import User from './pages/accountsForm/user/User';
import { Api } from './components/api';
import HistoryReplay from './pages/route/HistoryReplay';
import Driver from './pages/accountsForm/drivers/Driver';
import BusHostess from './pages/accountsForm/bushostess/BusHostess';
import Customer from './pages/accountsForm/customers/Customer';
import AdminSystem from './pages/accountsForm/adminsystem/AdminSystem';
import MainCommand from './pages/commands/MainCommand';
import HelpdeskMain from './pages/helpdesk/HelpdeskMain';
import MainModelGps from './pages/modelgps/MainModelGps';
import SpeedChartV2 from './pages/chartSpeedv2/SpeedChartV2';
import SitWorkReport from './pages/sitwork/SitWorkReport';
import MonitorHome from './pages/monitor/MonitorHome';
// import ReportBooking from './pages/mainbooking/ReportBooking';
// import CalendarCars from './pages/mainbooking/CalendarCars';
import MainLogEvents from './pages/logevents/MainLogEvents';
import LogEventsOverspeedByDevice from './pages/logevents/LogEventsOverspeedByDevice';
import LogEventsOverspeedByGroup from './pages/logevents/LogEventsOverspeedByGroup';
import LogEventsCardSwipe from './pages/logevents/LogEventsCardSwipe';
import LogEventCardSwipeByGroup from './pages/logevents/LogEventCardSwipeByGroup';
// import ShareCars from './pages/mainbooking/ShareCars';
import HomeBooking from './pages/bookings/HomeBooking';
import OverviewBooking from './pages/bookings/OverviewBooking';
import BookingReport from './pages/bookings/BookingReport';
import BookingCreate from './pages/bookings/BookingCreate';
import BookingUpdate from './pages/bookings/BookingUpdate';
import BookingSettings from './pages/bookings/BookingSettings';
import SettingEvents from './pages/bookings/SettingEvents';
import SettingCompanys from './pages/bookings/SettingCompanys';
import PoiV2LogByDevice from './pages/event/PoiV2LogByDevice';
import OfflinedeviceSumDate from './pages/event/OfflinedeviceSumDate';
import FormAES from './query/FormAES';
import SettingCars from './pages/bookings/SettingCars/SettingCars';
import SettingDriver from './pages/bookings/SettingDriver/SettingDriver';
import OfflinedeviceByCompany from './pages/event/OfflinedeviceByCompany';

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  const { closeSnackbar } = useSnackbar();
  const listTables = useResetRecoilState(listTablesAtom)





  useEffect(() => {
    closeSnackbar()
    listTables()
  }, [location, closeSnackbar, listTables])






  // const aesCbc = UseAesCbc();
  const account = useRecoilValue(userAtom);



  // const permissions = useRecoilValue(permissionAtom);
  const listModel = useRecoilValue(listModelAtom);
  const dltVender = useRecoilValue(dltVenderAtom)
  const listVehicleType = useRecoilValue(listVehicleTypeAtom)
  const listProvince = useRecoilValue(listProvinceAtom)
  const listVehicleRegisterType = useRecoilValue(listVehicleRegisterTypeAtom)
  const userId = localStorage.getItem('userId')
  // const actionsPermission = usePermission();
  // useEffect(() => {
  //   actionsPermission.getAndSet()
  // }, [])

  const [permissions, setPermissions] = useState([])
  const [loading, setloading] = useState(true)


  useEffect(() => {
    Api.get(`/v0/if/permission?userid=${userId}`)
      .then((res) => {
        setPermissions(res.data)
        setloading(false)
      })
      .catch((err) => {
        setloading(false)
        setPermissions([])
      })
  }, [userId, account])


  return useRoutes([
    {
      path: '/',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        {
          index: true, element:
            <RouterHasPermission
              userid={userId}
              permission={permissions.find(find => find.permission === "dashboard")}
              loading={loading}
              // scope={["read"]}
              // RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
              RenderHasPermission={
                ({ permissions }) => {
                  return (
                    <MainDashboard permissions={permissions} account={account} userId={userId} />
                  )
                }
              }
            />
        }
      ]
    },
    {
      path: '/',
      element: <NoneLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgotpassword', element: <Login /> },
        { path: 'register', element: <Register /> },
      ]
    },
    {
      path: '/report',
      element: <PrivateRoute><ReportLayout /></PrivateRoute>,
      children: [
        { index: true, element: <Navigate to="/report/event" replace /> },
        { path: 'route', element: <NoPermission message={'No Permission'} /> },
        { path: 'event', element: <EventMain account={account} /> },
        {
          path: 'event/overspeedbydevice', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "overspeedbydevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <OverspeedByDevice permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/overspeedbygroup', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "overspeedbygroup")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <OverspeedByGroup permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/overspeedbydate', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "overspeedbydate")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <OverspeedByDate permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/overspeedbydate2', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "overspeedbydate2")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <OverspeedByDateTwo permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/cardswipe', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "cardswipebydevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <CardSwipe permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/cardswipebygroup', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "cardswipebygroup")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <CardSwipeByGroup permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/cardswipetotalbydate', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "cardswipetotalbydate")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <CardSwipeTotalByEvents permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/odometerbydate', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "odometerbydate")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <OdometerByEvents permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/logevents', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "logevents")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <LogEvents permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/logeventsbygroup', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "logeventsbygroup")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <LogEventsByGroup permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/poiv2logbydevice', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "poilogbydevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <PoiV2LogByDevice permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/poilogbydevice', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "poilogbydevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <PoiLogByDevice permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/poilogbygroup', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "poilogbygroup")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <PoiLogByGroup permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/offlinedevice', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "offlinedevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Offlinedevice permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'event/offlinedevicesumdate', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "offlinedevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <OfflinedeviceSumDate permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        // const pCanme = (contype === "00" || contype === "01") ? `` : `&cname=${c_coname}`
        {
          path: 'event/offlinedevicebycompany', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "offlinebycompany")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  (account.contype === "00" || account.contype === "01") ?
                    <OfflinedeviceByCompany permissions={permissions} account={account} userId={userId} />
                    :
                    <NoPermission message={'No Permission'} />

                )
              }
            }
          />
        },

        { path: 'trip', element: <NoPermission message={'No Permission'} /> },
        { path: 'stop', element: <NoPermission message={'No Permission'} /> },

        {
          path: 'ev-summary', element:
            <RouterHasPermission
              userid={userId}
              permission={permissions.find(find => find.permission === "evsummary")}
              loading={loading}
              scope={["read"]}
              RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
              RenderHasPermission={
                ({ permissions }) => {
                  return (
                    <EvSummary permissions={permissions} account={account} userId={userId} />
                  )
                }
              }
            />
        },
        {
          path: 'chart', element: <MainChart />
        },
        { path: 'chart/speed', element: <SpeedChart account={account} userId={userId} /> },
        { path: 'chart/speedv2', element: <SpeedChartV2 account={account} userId={userId} /> },
        {
          path: 'chart/speedmonth', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "chartsumoverspeed")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <SpeedChartMonth permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        { path: 'log', element: <Log account={account} userId={userId} /> },
        // { path: 'dlt', element: <Dlt account={account} /> },
        {
          path: 'dlt_cer', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "dltcer")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <DltCer permissions={permissions} account={account} />
                )
              }
            }
          />
        },
        // { path: 'dlt_cer', element: account?.contype === '00' || userId === "sit" ? <DltCer account={account} /> : <NoPermission message={'No Permission'} /> },
        {
          path: 'work', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "reportwork")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <MainWork permissions={permissions} />
                )
              }
            }
          />
        },

        // {
        //   path: 'workbyuser', element: <MainWorkByUser />
        // }
        {
          path: 'helpdesk', element: <HelpdeskMain />
        }
      ]
    },
    {
      path: '/settings',
      element: <PrivateRoute><SettingsLayout /></PrivateRoute>,
      children: [
        { index: true, element: <Navigate to="/settings/preferences" replace /> },
        { path: 'preferences', element: <NoPermission message={'No Permission'} /> },
        { path: 'routes', element: <NoPermission message={'No Permission'} /> },
        {
          path: 'groups', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "groupsdevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Groups permissions={permissions} userId={userId} account={account} />
                )
              }
            }
          />
        },
        { path: 'notifications', element: <NoPermission message={'No Permission'} /> },
        {
          path: 'customers', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "customers")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Customer permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'accounts', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "accounts")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <User permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'devices', element:
            <RouterHasPermission
              userid={userId}
              permission={permissions.find(find => find.permission === "devices")}
              loading={loading}
              scope={["read"]}
              RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
              RenderHasPermission={
                ({ permissions }) => {
                  return (
                    <MainDevice permissions={permissions} account={account} />
                  )
                }
              }
            />
        },
        {
          path: 'drivers', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "drivers")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Driver permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'bushostess', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "bushostess")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <BusHostess permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },

        { path: 'calendars', element: <NoPermission message={'No Permission'} /> },
        { path: 'attributes', element: <NoPermission message={'No Permission'} /> },
        { path: 'maintenances', element: <NoPermission message={'No Permission'} /> },
        {
          path: 'commands', element:
            <RouterHasPermission
              userid={userId}
              permission={permissions.find(find => find.permission === "commands")}
              loading={loading}
              scope={["send"]}
              RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
              RenderHasPermission={
                ({ permissions }) => {
                  return (
                    <MainCommand />
                  )
                }
              }
            />
        },
        // { path: 'commands-t', element: <MainCommand /> },
        // { path: 'booking', element: <MainBooking /> },
        {
          path: 'geofences/geofence/:id', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "geofences")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <GeofencesForm permissions={permissions} account={account} />
                )
              }
            }
          />
        },
        {
          path: 'geofences/geofence', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "geofences")}
            loading={loading}
            // scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <GeofencesNew permissions={permissions} account={account} />
                )
              }
            }
          />
        },
        {
          path: 'contypes', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "contypes")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Contypes permissions={permissions} account={account} />
                )
              }
            }
          />
        },
        {
          path: 'concodes', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "concodes")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Concode permissions={permissions} account={account} />
                )
              }
            }
          />
        },
        {
          path: 'prefixs', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "prefixs")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Prefixs permissions={permissions} account={account} />
                )
              }
            }
          />
        },
        {
          path: 'companys', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "companys")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Company permissions={permissions} account={account} />
                )
              }
            }
          />
        },
        {
          path: 'sexs', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "sexs")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Sexs permissions={permissions} account={account} />
                )
              }
            }
          />
        },
        {
          path: 'administrator', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "admin")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Admin permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'adminsystem', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "admin")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  account?.contype === '00' ? <AdminSystem permissions={permissions} account={account} userId={userId} /> : <></>
                )
              }
            }
          />
        },
        {
          path: 'grouppermission', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "grouppermission")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <GroupPermission permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        { path: 'address', element: <NoPermission message={'No Permission'} /> },
        {
          path: 'modelgps', element:
            <NoPermission message={'No Permission'} />
          // <MainModelGps account={account} userId={userId} />
        },
        // { path: 'jobs', element: <>jobs</> },
      ]
    },
    {
      path: '/account',
      element: <PrivateRoute><AccountLayout /></PrivateRoute>,
      children: [
        // {index: true, element: <Navigate to="/" replace /> },
        { index: true, element: <Navigate to="/account/profile" replace /> },
        { path: 'profile', element: <SettingsProfile account={account} /> },
        { path: 'changepass', element: <ChangepassProfile account={account} userId={userId} /> },
      ]
    },
    {
      path: '/report/replay',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        {
          index: true, element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "replay")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Replay permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
      ]
    },
    {
      path: '/report/replayv2',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        {
          index: true, element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "replay")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <HistoryReplay permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        }
      ]
    },
    {
      path: '/report/events',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        {
          index: true, element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "reportevents")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <Events permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        }
      ]
    },
    {
      path: '/settings/geofences',
      element: <PrivateRoute>
        <RouterHasPermission
          userid={userId}
          permission={permissions.find(find => find.permission === "geofences")}
          loading={loading}
          scope={["read"]}
          RenderError={() => { return (<SettingsLayout />) }}
          RenderHasPermission={
            ({ permissions }) => {
              return (
                <NoneLayout />
              )
            }
          }
        />
      </PrivateRoute>,
      children: [
        {
          index: true, element:
            <RouterHasPermission
              userid={userId}
              permission={permissions.find(find => find.permission === "geofences")}
              loading={loading}
              scope={["read"]}
              RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
              RenderHasPermission={
                ({ permissions }) => {
                  return (
                    <Geofences permissions={permissions} account={account} />
                  )
                }
              }
            />
        },
      ]
    },
    // {
    //   path: '/settings/poi',
    //   element: <PrivateRoute>
    //     <NoneLayout />
    //   </PrivateRoute>,
    //   children: [
    //     {
    //       index: true, element:
    //         <RouterHasPermission
    //           userid={userId}
    //           permission={permissions.find(find => find.permission === "poi")}
    //           loading={loading}
    //           scope={["read"]}
    //           RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
    //           RenderHasPermission={
    //             ({ permissions }) => {
    //               return (
    //                 <MainPoi permissions={permissions} account={account} />
    //               )
    //             }
    //           }
    //         />
    //     }
    //   ]
    // },
    {
      path: '/settings/poiv2',
      element: <PrivateRoute>
        <NoneLayout />
      </PrivateRoute>,
      children: [
        {
          index: true, element:
            <RouterHasPermission
              userid={userId}
              permission={permissions.find(find => find.permission === "poi")}
              loading={loading}
              scope={["read"]}
              RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
              RenderHasPermission={
                ({ permissions }) => {
                  return (
                    <MainPoiX permissions={permissions} account={account} />
                  )
                }
              }
            />
        }
      ]
    },
    {
      path: '/log',
      element: <PrivateRoute><DashboardLayout /></PrivateRoute>,
      children: [
        { index: true, element: account?.contype === '00' ? <Log /> : <NoPermission message={'No Permission'} /> }
      ]
    },
    {
      path: '/report/dlt_cer/print/:id',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        {
          index: true, element:
            <RouterHasPermission
              userid={userId}
              permission={permissions.find(find => find.permission === "dltcer")}
              loading={loading}
              scope={["print"]}
              RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
              RenderHasPermission={
                ({ permissions }) => {
                  return (
                    <PrintDltCer permissions={permissions} account={account} />
                  )
                }
              }
            />
        }
      ]
    },
    {
      path: '/report/dlt_cer/print_dltdate/:dltdate',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        {
          index: true, element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "dltcer")}
            loading={loading}
            scope={["download"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <PrintDltCerDate permissions={permissions} account={account} />
                )
              }
            }
          />
        }
      ]
    },
    {
      path: '/mapgl',
      element: <NoneLayout />,
      children: [
        { index: true, element: <MapGl /> },
      ]
    },
    {
      path: '/sitworks',
      element: <PrivateRoute><ReportLayout /></PrivateRoute>,
      children: [
        { index: true, element: <SitWorkReport /> },
      ]
    },
    {
      path: '/monitor',
      element: <PrivateRoute><ReportLayout /></PrivateRoute>,
      children: [
        { index: true, element: <MonitorHome /> },
      ]
    },
    {
      path: 'report/logevents', element: <PrivateRoute><ReportLayout /></PrivateRoute>,
      children: [
        { index: true, element: <MainLogEvents /> },
        {
          path: 'overspeeddevice', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "overspeedbydevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <LogEventsOverspeedByDevice permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'overspeedbygroup', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "overspeedbygroup")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <LogEventsOverspeedByGroup permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'cardswipebydevice', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "cardswipebydevice")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <LogEventsCardSwipe permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
        {
          path: 'cardswipebygroup', element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "cardswipebygroup")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <LogEventCardSwipeByGroup permissions={permissions} account={account} userId={userId} />
                )
              }
            }
          />
        },
      ]
    },

    {
      path: '/booking',
      element: <PrivateRoute><ReportLayout /></PrivateRoute>,
      children: [
        {
          index: true, element: <RouterHasPermission
            userid={userId}
            permission={permissions.find(find => find.permission === "booking")}
            loading={loading}
            scope={["read"]}
            RenderError={() => { return (<NoPermission message={'No Permission'} />) }}
            RenderHasPermission={
              ({ permissions }) => {
                return (
                  <HomeBooking userId={userId} />
                )
              }
            }
          />
        },
        { path: 'overview', element: <OverviewBooking userId={userId} /> },
        { path: 'report', element: <BookingReport userId={userId} /> },
        { path: 'create', element: <BookingCreate userId={userId} /> },
        { path: 'update/:id', element: <BookingUpdate userId={userId} /> },
        { path: 'settings', element: <BookingSettings userId={userId} /> },
        { path: 'settings/events', element: <SettingEvents userId={userId} /> },
        { path: 'settings/companys', element: <SettingCompanys userId={userId} /> },
        { path: 'settings/cars', element: <SettingCars userId={userId} /> },
        { path: 'settings/driver', element: <SettingDriver userId={userId} /> }
      ]
    },

    {
      path: '/query',
      element: <NoneLayout />,
      children: [
        { index: true, element: <FormAES /> },
      ]
    },

    // {
    //   path: '/sharecars',
    //   element: <NoneLayout />,
    //   children: [
    //     { index: true, element: <ShareCars /> },
    //   ]
    // },

    {
      path: '/404',
      element: <NoneLayout />,
      children: [
        { index: true, element: <>404</> },
      ]
    },
    // {path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const NoPermission = ({ message }) => {
  return (
    <Typography textAlign="center" variant="h4" marginTop="20px"> {message}</Typography>
  )
}