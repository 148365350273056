import React, { useEffect, useState } from 'react';
import { Box, Collapse, TextField, Badge, MenuItem, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, CircularProgress, Backdrop, Select } from '@mui/material'
import { fDateTo } from '../../../utils/formatTime';
import _, { values } from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TableOverViewEmployee from './TableOverViewEmployee';
import SearchIcon from '@mui/icons-material/Search';

import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
const OverviewEmployee = ({ onHandle, filter = [], userId, title }) => {
  const [open, setOpen] = useState(false)



  const [page, setPage] = useState(1)
  const [pageMax, setPageMax] = useState(1)
  const [initialData, setInitialData] = useState([])
  const [loading, setloading] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, "0"))
  const [search, setSearch] = useState("")

  const handleOpen = () => {
    setOpen(true)
    setPage(1)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      setloading(true)
      const url = `https://booking.sitgps.com/booking/get_work_employee?page=${page}&size=14&date=${year}-${month}`
      axios.get(url)
        .then(res => {
          const iFllter = res.data.data
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []
            const databookings = bookings.map(v => {
              return {
                ...v,
                "date_start": (v.reserve_datetime_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.reserve_datetime_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })
            const dataevents = events.map(v => {
              return {
                ...v,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })
            return {
              "userid": d.userid,
              "fname_lname": d.fname_lname,
              "position": d.position,
              "department": d.department,
              "department2": d.department2,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
          setPageMax(res.data.pagesize)
          setSearch("")
          setloading(false)
        })
        .catch(error => {
          setloading(false)
        })
    }
  }, [open, page, month])


  const handleEventClick = (event) => {
    onHandle(event)
    handleClose()
  };

  const calculateMaxDate = () => {
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    return lastDayOfMonth
  };

  const onSearch = (e) => {
    setSearch(e.target.value)
  }

  const initialdataFillter = initialData.filter((c, i) => c.userid.toLowerCase().includes(search.toLowerCase()))



  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          {title}
        </Box>
        <Box>
          <Button variant="outlined" size="small" color="error"
            onClick={handleOpen}
          >
            <AddOutlinedIcon fontSize="small" />
          </Button>
        </Box>
      </Stack>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth={true}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            style: {
              // background: "#F8F8FF", opacity: 0.3
              background: "#a7d8de", opacity: 0.2,
              position: "relative"
              // background: "#000", opacity: 0.1
            }
          }
        }}
        sx={{
          '& .MuiPaper-root': {
            marginBottom: '0px',
            marginTop: '0px',
            maxHeight: "calc(100%)"
          }
        }}
      >
        <DialogTitle>เลือกพนักงาน</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { setOpen(false) }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            <Stack direction="row" spacing={1}>
              <TextField size="small" label="ค้นหา" onChange={onSearch} value={search} />
              <TextField size="small"
                type="month"
                value={`${year}-${(month)}`}
                onChange={(e) => {
                  const d = e.target.value
                  const yearNmonth = d.split("-")
                  setYear(yearNmonth[0])
                  setMonth(yearNmonth[1])
                  setPage(1)
                }} />
              <Box>
                <Stack spacing={0.2} direction="row" justifyContent="space-between">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setPage(p => { return p - 1 })
                    }}
                    disabled={page === 1}
                  >
                    <ArrowCircleLeftTwoToneIcon fontSize="small" />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setPage(p => { return p + 1 })
                    }}
                    disabled={page === pageMax}
                    size="small"
                  >
                    <ArrowCircleRightTwoToneIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box>
            <TableContainer style={{ maxHeight: "calc(100vh - 153px)", minHeight: "calc(100vh - 153px)" }}>
              <Table stickyHeader size="small" style={{ border: "none", userSelect: "none" }} >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1302, textAlign: "center" }} colSpan={calculateMaxDate()} >
                      {`${month}/${year}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1303 }}>
                      <Box>{`ชื่อ-(รหัสพนักงาน)`}</Box>
                    </TableCell>

                    {
                      [...Array(calculateMaxDate())].map((value, index) => {
                        return (
                          <TableCell key={`Table-TableCell-${index}`} style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1302 }}>{`${(index + 1).toString().padStart(2, "0")}`}</TableCell>
                        )
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody >
                  <TableOverViewEmployee userId={userId} open={open} dataFillter={initialdataFillter} handleEventClick={handleEventClick} maxDate={calculateMaxDate()} year={year} month={month} />
                </TableBody>
              </Table>
            </TableContainer >
          </Box>
        </DialogContent >
        <DialogActions>
        </DialogActions>
      </Dialog >
    </Box >
  );
};

export default OverviewEmployee;
