import styled from '@emotion/styled';
import { DatePicker, DateTimePicker, LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Container, Stack, Divider, Grid, TextField, Typography, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Dialog, DialogContent, useMediaQuery, TableSortLabel } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';
import { Api } from '../../components/api';
import { fDateTo } from '../../utils/formatTime';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import TreeViewGroup from './TreeViewGroup';
import { useReactToPrint } from 'react-to-print';
import HasPermission from '../../haspermission/HasPermission';
import _ from 'lodash';

export default function OfflinedeviceSumDate({ account, userId, permissions }) {
    const navigate = useNavigate()
    const [devices, setDevices] = useState([])
    const [poiLogs, setPoiLogs] = useState([])

    useEffect(() => {
        Api.get(`/Tracking/DeviceByUsers?userid=${userId}`).then((res) => {
            var list = res.data.map(d => {
                return {
                    value: d.group_id,
                    label: d.group_name,
                    children: d.device.map(c => {
                        return {
                            value: c.id,
                            label: c.name
                        }
                    })
                }
            });
            setDevices(list)
        }
        ).catch(e => {
            console.log('error', e)
        })
    }, [userId])

    const schema = Yup.object().shape({
        date: Yup.date().required('minute is required'),
    });
    const selectedTime = new Date();
    const formik = useFormik({
        initialValues: {
            date: fDateTo(new Date(), "yyyy-MM-dd HH:mm:ss")
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { date } = values
            const { cocode } = account;
            const req = {
                "userId": userId,
                "list": checked
            }
            Api.post('/Tracking/DevicesByList', req).then((res) => {
                const { data } = res
                setPoiLogs(_.orderBy(data, ["datetime"], ["asc"]))
                setSubmitting()
            }).catch((e) => {
                setPoiLogs([])
                setSubmitting()
            })
        }
    })
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
    const [checked, setChecked] = useState([])
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onChangeDate = (value, name) => {
        if (String(value) === "Invalid Date") {
            setFieldValue(name, value)
        } else {
            setFieldValue(name, fDateTo(value, "yyyy-MM-dd HH:mm:ss"))
        }
    }


    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    function calculateTimeDifference(startDate) {
        const now = new Date().getTime();
        const startTimestamp = new Date(startDate).getTime();
        const difference = now - startTimestamp;
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

        return { days, hours, minutes };
        // return `${days} วัน ${hours} ชั่วโมง ${minutes} นาที`;
    }


    const filterTimeDifference = () => {
        const now = new Date();
        const cutoffTime = new Date();
        cutoffTime.setMinutes(now.getMinutes() - 30);
        return poiLogs.filter(item => {
            const itemDate = new Date(item.datetime);
            return itemDate < cutoffTime;
        });

    }

    return (
        <Page title="Offline device">
            <Container>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Event : Offline device sum date</Typography>
                </Box>
                <Box flexGrow={1} padding={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} >
                                        <TreeViewGroup
                                            nodes={devices}
                                            checked={checked}
                                            setChecked={setChecked}
                                            maxHeight={"180px"}
                                            height={"180px"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <LoadingButton
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitting}
                                            >
                                                ค้นหา
                                            </LoadingButton>

                                            <Button
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="info"
                                                onClick={() => {
                                                    navigate('/report/event')
                                                }}

                                            >
                                                กลับ
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </Box>
            </Container >
            <MainStyle>
                <Container sx={{
                    padding: 3,
                    // justifyContent: "center", display: "flex"
                }} >
                    <Box>
                        <Stack justifyContent="space-between" direction="row" spacing={1} >
                            <Box></Box>
                            <Box>
                                {filterTimeDifference().length !== 0 && (

                                    <Stack direction="row" spacing={1}>
                                        <HasPermission permissions={permissions} scope={["print"]}>
                                            <Button onClick={handlePrint} variant="outlined">พิมพ์</Button>
                                        </HasPermission>
                                        <HasPermission permissions={permissions} scope={["download"]}>
                                            <Button
                                                as={CSVLink}
                                                filename={`${fDateTo(values.date, 'dd_MM_yyyy_HH_mm')}.csv`} data={
                                                    filterTimeDifference().map((value, index) => {
                                                        const {
                                                            device, name, datetime, poi_name, poi_id
                                                        } = value;
                                                        return {
                                                            "เลขที่อุปกรณ์": `=""${device}""`,
                                                            "ทะเบียนรถ": `=""${name}""`,
                                                            "วันที่ออนล่าสุด": `=""${fDateTo(new Date(datetime), 'dd/MM/yyyy HH:mm:ss')}""`,
                                                            "วัน": `=""${calculateTimeDifference(value.datetime)?.days}""`,
                                                            "ชั่วโมง": `=""${calculateTimeDifference(value.datetime)?.hours}""`,
                                                            "นาที": `=""${calculateTimeDifference(value.datetime)?.minutes}""`,
                                                            "สถานที่": `=""${value?.widget_bar?.value}""`,
                                                        }
                                                    }
                                                    )
                                                }
                                                variant="contained"
                                                style={{
                                                    textDecorationLine: 'none',
                                                }} >
                                                Download
                                            </Button>
                                        </HasPermission>
                                    </Stack>
                                )}

                            </Box>
                        </Stack>
                    </Box>

                    <Box ref={componentRef} className="section-to-print">
                        <Box sx={{
                            marginLeft: "0.5cm", marginRight: "0.5cm"
                        }}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={200}>
                                                เลขที่อุปกรณ์
                                            </TableCell>
                                            <TableCell width={120}>
                                                ทะเบียนรถ
                                            </TableCell>
                                            <TableCell width={200}>
                                                วันที่ออนล่าสุด
                                            </TableCell>
                                            <TableCell width={80} align="right">
                                                วัน
                                            </TableCell>
                                            <TableCell width={80} align="right">
                                                ชั่วโมง
                                            </TableCell>
                                            <TableCell width={80} align="right">
                                                นาที
                                            </TableCell>
                                            <TableCell>
                                                สถานที่
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filterTimeDifference().map((value, index) => {
                                                return (
                                                    <TableRow sx={{ padding: "10px" }} key={index}>
                                                        <TableCell>{value.device}</TableCell>
                                                        <TableCell>{value.name}</TableCell>
                                                        <TableCell>{fDateTo(value.datetime, 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                        <TableCell align="right">{calculateTimeDifference(value.datetime)?.days}</TableCell>
                                                        <TableCell align="right">{calculateTimeDifference(value.datetime).hours}</TableCell>
                                                        <TableCell align="right">{calculateTimeDifference(value.datetime).minutes}</TableCell>
                                                        <TableCell>{value?.widget_bar?.value}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Container>
            </MainStyle>
        </Page >
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));