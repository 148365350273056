import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem, FormControlLabel } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import CustomerDialog from './CustomerDialog'
export default function BookingCreateCarsOverView({ open, data, handleClose, handleCancel, userId, typeCar }) {

  const [count, setCount] = useState("1")
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState(false);

  const [dataForm, setDataForm] = useState({
    "vehicle_id": "",
    "date_start": "",
    "date_end": "",
    "contact_prefix": "",
    "contact_firstname": "",
    "contact_lastname": "",
    "contact_phone": "",
    "contact_note": "",
    "company_id": "",
    "company_name": "",
    "company_note2": "",
    "prefix": "",
    "firstname": "",
    "company_id": "",
    "company_name": "",
    "lastname": "",
    "taxid": "",
    "branch": "",
    "address": "",
    "subdistrict": "",
    "district": "",
    "province": "",
    "postcode": "",
    "phone": "",
  })
  useEffect(() => {
    setDataForm(d => {
      return { ...d, ...data }
    })
  }, [data])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClickVariant = ({ message, variant }) => {
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };

  const onSubmit = () => {
    const dataCreateHeader = {
      "booking_userid": userId,
      "type_group": "",
      "type_booking": "",
      "datetime_start": dataForm.date_start,
      "datetime_end": dataForm?.date_end,
      "type_address": "",
      "prefix": "",
      "firstname": dataForm.company_name,
      "company_id": dataForm.company_id,
      "company_name": dataForm.company_name,
      "lastname": dataForm.lastname,
      "taxid": dataForm.taxid,
      "branch": dataForm.branch,
      "address": dataForm.address,
      "subdistrict": dataForm.subdistrict,
      "district": dataForm.district,
      "province": dataForm.province,
      "postcode": dataForm.postcode,
      "phone": dataForm.phone,
      "work_ref": "",
      "vehicle_list": JSON.stringify([{ ...typeCar, "count": `${count}` }]),
      "guest_count": "",
      "salecode": "",
      "salename": "",
      "company_note2": dataForm.company_note2,
      "contact_prefix": dataForm.contact_prefix,
      "contact_firstname": dataForm.contact_firstname,
      "contact_lastname": dataForm.contact_lastname,
      "contact_phone": dataForm.contact_phone,
      "contact_req": "",
      "contact_note": dataForm.contact_note,
      "status_payment": "",
      "status_trip": "",
      "trip_data": []
    }
    axios.post(`https://booking.sitgps.com/booking/book_h`, { ...dataCreateHeader })
      .then(res => {
        const booking_id = res.data.booking_id
        const trip = {
          "trip_type": "งานหลัก",
          "trip_start_datetime": dataForm.date_start,
          "trip_end_datetime": dataForm.date_end,
          "booking_id": res.data.booking_id,
          "booking_userid": userId
        }
        axios.post(`https://booking.sitgps.com/booking/create_trip`, trip)
          .then(res => {
            const trip_id = res.data.trip_id
            axios.post(`https://booking.sitgps.com/booking/book_d`, {
              "booking_id": booking_id,
              "trip_id": trip_id,
              "type_car": "",
              "vehicle_id": dataForm.vehicle_id,
              "driver_id": [],
              "reception_id": [],
              "station_start": "",
              "station_start_datetime": dataForm.date_start,
              "station_end_datetime": dataForm.date_end,
              "station_start_flight": "",
              "station_end": "",
              "station_end_flight": "",
              "note": dataForm.note,
              "booking_userid": userId
            })
              .then(res => {
                handleClickVariant({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
                handleClose()
              })
              .catch(err => {
                console.log('err', err)
              })
          })
          .catch(err => {
            console.log('err', err)
          })
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setDataForm(d => {
      return { ...d, [name]: value }
    })
  }


  const onChangeCustomer = (e) => {
    const { company_id, company_name, company_note2 } = e
    setDataForm(d => {
      return { ...d, company_id: company_id, company_name, company_name, company_note2, company_note2 }
    })
    setError(false);
  }

  return (
    <Dialog
      open={open}
      // fullScreen
      maxWidth="sm"
      onClose={handleCancel}
      slotProps={{
        backdrop: {
          style: {
            // background: "#F8F8FF", opacity: 0.3
            background: "#a7d8de", opacity: 0.2
            // background: "#000", opacity: 0.1
          }
        }
      }}
    >
      <DialogTitle>สร้าง</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <form onSubmit={e => {
          e.preventDefault()
          if (dataForm.company_id === "") {
            setError(true);
          } else {
            setError(false);
            onSubmit()
          }
        }} noValidate>
          <Grid container spacing={1} >
            <Grid item xs={6} md={6}>
              <TextField size="small" label='ทะเบียน' value={dataForm.vehicle_id} name='vehicle_id' fullWidth disabled />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField size="small" label='เลขที่อ้างอิง' onChange={onChange} value={dataForm.work_ref} name='work_ref' fullWidth />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField size="small" type="datetime-local" onChange={onChange} value={dataForm.date_start} label='วันที่' name='date_start' fullWidth />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField size="small" type="datetime-local" onChange={onChange} value={dataForm.date_end} label='ถึงวันที่' name='date_end' fullWidth />
            </Grid>

            <Grid item xs={6} md={6}>
              <TextField size="small" value={`${typeCar?.color}/${typeCar?.seat}/${typeCar?.fuel}`} label="ปรถเภทรถ" disabled fullWidth />
              {/* [{"color":"ขาวลาย","seat":"44","fuel":"แก๊สล้วน"}] */}
              {/* ,"count": "" */}
              {/* {JSON.stringify(typeCar)} */}
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField size="small" value={count} label="จำนวน" fullWidth onChange={e => { setCount(e.target.value) }} />
            </Grid>


            <Grid item xs={12} md={12}>
              <Collapse in={true} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <TextField size="small" label='บริษัท' fullWidth
                      // InputLabelProps={{ shrink: formCus.name ? true : false }}

                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" >
                            <CustomerDialog onHandle={(d) => { onChangeCustomer(d) }} />
                          </InputAdornment>
                        ),
                      }}
                      disabled
                      value={dataForm.company_name}
                      name='company_name'
                      // helperText={"required"}
                      helperText={error ? "required" : ""}
                      sx={{
                        '& .MuiFormHelperText-root': {
                          color: 'red',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField size="small" label='เงื่อนไข' name='company_note2' fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: dataForm.company_note2 !== "",
                      }}
                      multiline
                      rows={2}
                      value={dataForm.company_note2}
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={<Checkbox
                  value={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked)
                  }}
                />}
                label="ใส่ข้อมูลลูกค้า"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Collapse in={checked} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Grid container spacing={1} >
                  <Grid item xs={12} md={2}>
                    <TextField size="small" label='คำนำหน้า' name='prefix' fullWidth
                      value={dataForm.prefix}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField size="small" label='ชื่อ' fullWidth
                      // InputLabelProps={{ shrink: formCus.name ? true : false }}
                      onChange={onChange}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end" >
                      //       <CustomerDialog onHandle={(d) => { onChangeCustomer(d) }} />
                      //     </InputAdornment>
                      //   )
                      // }}
                      value={dataForm.firstname}
                      name='firstname'
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField size="small" label='นามสกุล' fullWidth
                      value={dataForm.lastname}
                      onChange={onChange}
                      name='lastname'
                    />
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <TextField size="small" label='เลขที่ผู้เสียภาษี' name='taxid' fullWidth
                      value={dataForm.taxid !== null ? dataForm.taxid : ""}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <TextField size="small" label='สาขา' name='branch' fullWidth
                      value={dataForm.branch}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={9} md={9}>
                    <TextField size="small" label='ที่อยู่' name='address' fullWidth
                      value={dataForm.address}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <TextField size="small" label='ประเภทที่อยู่' name='address_type' fullWidth
                      value={dataForm.address_type}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField size="small" label='แขวง/ตำบล' name='subdistrict' fullWidth
                      value={dataForm.subdistrict}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField size="small" label='เขต/อำเภอ' name='district' fullWidth
                      value={dataForm.district}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField size="small" label='จังหวัด' name='province' fullWidth
                      value={dataForm.province}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField size="small" label='รหัสไปรษณีย์' name='postcode' fullWidth
                      value={dataForm.postcode}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField size="small" label='เบอร์ติดต่อ' name='phone' fullWidth
                      value={dataForm.phone}
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography>ผู้ติดต่อ</Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <TextField size="small" value={dataForm.contact_prefix} onChange={onChange} label='คำนำหน้า' name='contact_prefix' fullWidth />
                </Grid>
                <Grid item xs={5}>
                  <TextField size="small" value={dataForm.contact_firstname} onChange={onChange} label='ชื่อ' name='contact_firstname' fullWidth

                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField size="small" value={dataForm.contact_lastname} onChange={onChange} label='นามสกุล' name='contact_lastname' fullWidth />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField size="small" value={dataForm.contact_phone} onChange={onChange} label='เบอร์โทร' name='contact_phone' fullWidth />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField size="small" value={dataForm.contact_note} onChange={onChange} label='หมายเหตุ' multiline maxRows={3} minRows={3}
                name='contact_note' fullWidth />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="outlined">บันทึก</Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}
