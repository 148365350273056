import { alpha, Box, Card, Container, Divider, Grid, Typography } from '@mui/material'
import { styled } from '@mui/styles';
import React from 'react'
import Page from '../../components/Page'
import SpeedIcon from '@mui/icons-material/Speed';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import { useNavigate } from 'react-router-dom';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PortableWifiOffOutlinedIcon from '@mui/icons-material/PortableWifiOffOutlined';
export default function EventMain({ account }) {
    const navigate = useNavigate()
    return (
        <Page>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Event</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    <Box>
                        <Box padding="10px">
                            <Typography variant="h5" >Speed</Typography>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/overspeedbydevice')
                                }}>
                                    <IconWrapperStyle>
                                        <SpeedIcon fontSize="large" color="error" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Overspeed by device
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/overspeedbygroup')
                                }}>
                                    <IconWrapperStyle>
                                        <SpeedIcon fontSize="large" color="error" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Overspeed by group
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/overspeedbydate')
                                }}>
                                    <IconWrapperStyle>
                                        <SpeedIcon fontSize="large" color="error" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Overspeed by date
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/overspeedbydate2')
                                }}>
                                    <IconWrapperStyle>
                                        <SpeedIcon fontSize="large" color="error" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Overspeed/2 by date
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box padding="10px">
                            <Typography variant="h5" >Card swipe</Typography>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/cardswipe')
                                }}>
                                    <IconWrapperStyle>
                                        <CreditCardOffIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Card swipe by device
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/cardswipebygroup')
                                }}>
                                    <IconWrapperStyle>
                                        <CreditCardOffIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Card swipe by group
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/cardswipetotalbydate')
                                }}>
                                    <IconWrapperStyle>
                                        <CreditCardOffIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Card swipe total by date
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box padding="10px">
                            <Typography variant="h5" >Odometer</Typography>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/odometerbydate')
                                }}>
                                    <IconWrapperStyle>
                                        <SpeedIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Odometer by events
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box padding="10px">
                            <Typography variant="h5" >Log</Typography>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/logevents')
                                }}>
                                    <IconWrapperStyle>
                                        <ListOutlinedIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Log events
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/logeventsbygroup')
                                }}>
                                    <IconWrapperStyle>
                                        <ListOutlinedIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Log events by group
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/poilogbydevice')
                                }}>
                                    <IconWrapperStyle>
                                        <PlaceOutlinedIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        POI log by device
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/poiv2logbydevice')
                                }}>
                                    <IconWrapperStyle>
                                        <PlaceOutlinedIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        POI V2 log by device
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/poilogbygroup')
                                }}>
                                    <IconWrapperStyle>
                                        <PlaceOutlinedIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        POI log by group
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box padding="10px">
                            <Typography variant="h5" >Offline device</Typography>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/offlinedevice')
                                }}>
                                    <IconWrapperStyle>
                                        <PortableWifiOffOutlinedIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Offline device
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/event/offlinedevicesumdate')
                                }}>
                                    <IconWrapperStyle>
                                        <PortableWifiOffOutlinedIcon fontSize="large" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Offline device sum date
                                    </Typography>
                                </RootCardStyle>
                            </Grid>

                            {
                                (account.contype === "00" || account.contype === "01") && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RootCardStyle onClick={() => {
                                            navigate('/report/event/offlinedevicebycompany')
                                        }}>
                                            <IconWrapperStyle>
                                                <PortableWifiOffOutlinedIcon fontSize="large" />
                                            </IconWrapperStyle>
                                            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                                Offline device by company
                                            </Typography>
                                        </RootCardStyle>
                                    </Grid>
                                )
                            }

                        </Grid>
                    </Box>
                </MainStyle>
            </Container>
        </Page>
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));

const RootCardStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    // color: theme.palette.info.darker,
    // backgroundColor: theme.palette.info.lighter,
    backgroundColor: 'whitesmoke',
    cursor: 'pointer'
}));
const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    // borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.info.dark,
    // backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    //     theme.palette.info.dark,
    //     0.24
    // )} 100%)`
}));