import React, { useState } from 'react'
import { aesApi, aesEncryptECBHex } from './QueryAES'

export default function FormAES() {

    const [data, setData] = useState([])

    const onClick = () => {
        const req = {

            "database": "booking_prd",
            "data": aesEncryptECBHex(`SELECT booking_h.booking_id ,booking_h.booking_userid ,booking_h.type_group, booking_h.type_booking , booking_h.company_id,if_company.code as company_code,if_company.name as company_name,if_company.color as company_color, booking_h.guest_count as guest_count_h , booking_h.vehicle_list AS vehicle_count, COUNT(CASE WHEN booking_d.vehicle_id IS NOT NULL AND booking_d.vehicle_id != '' THEN 1 END) AS vehicle_count_book from booking_h left JOIN booking_trip ON booking_h.booking_id = booking_trip.booking_id left JOIN booking_d ON booking_trip.booking_id = booking_d.booking_id AND booking_trip.trip_id = booking_d.trip_id left JOIN if_company ON if_company.id = booking_h.company_id WHERE booking_h.datetime_start BETWEEN '2025-01-15' AND '2025-01-15' GROUP BY booking_h.booking_id,booking_userid,type_group,type_booking,company_id,if_company.code,if_company.name,if_company.color,booking_h.guest_count, booking_h.vehicle_list;`)
        }
        aesApi.post("http://119.110.241.46:1895/query", req)
            .then(res => {
                // setData(res.data)[booking_prd].dbo.[company_id]
                console.log('res.data', res.data)
            })
            .catch(err => {
                console.log('err', err)
            })
    }
    return (
        <div>
            <div>FormAES</div>
            <button onClick={onClick}> ส่ง </button>


            <div>
                {/* {
                    data.map((item, index) => {

                        return (
                            <div key={`row-${index}`}>
                                {`${JSON.stringify(item)}`}
                                <br />
                            </div>
                        )
                    })
                } */}
            </div>
        </div>
    )
}




